import "./styles.css";
import { Box } from "@mui/joy";
import { SxProps } from "@mui/system";
type CardProps = {
  children: React.ReactNode;
  sx?: SxProps;
};
export const Card = ({ children, sx }: CardProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#eee",
        // backgroundColor: "red",
        // padding: "8px",
        boxShadow: "1.0px 4.0px 4.0px hsl(0deg 0% 0% / 0.44)",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
