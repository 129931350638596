// Convert elevation_gain from meters to feet = * 3.28084
// Convert length from meters to miles = * 0.000621371

export const hikingData = [
  {
    objectID: "track-240279590",
    ID: 240279590,
    indexed_at: 1709673832,
    name: "Black Diamond",
    slug: "afternoon-hike-at-nortonville-coal-canyon-black-diamond-manhattan-and-chaparral-loop-b93f118",
    type: "track",
    _geoloc: {
      lat: 37.95886,
      lng: -121.86311,
    },
    length: 9362.67,
    elevation_gain: 388,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10587635,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 6,
    popularity: 0.8048,
    hidden: 0,
    city_name: "Antioch",
    state_name: "CA",
    country_name: "United States",
    created_at: "2024-02-16T18:26:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 161,
    distance: 9362.67,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-236958319",
    ID: 236958319,
    indexed_at: 1709673832,
    name: "Cow poop, pee and placenta",
    slug: "cow-poop-pee-and-placenta-254a5ec",
    type: "track",
    _geoloc: {
      lat: 37.89415,
      lng: -121.99442,
    },
    length: 7163.4,
    elevation_gain: 250,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10403194,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 4,
    popularity: 0.6024,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2024-02-03T22:22:49Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 117,
    distance: 7163.4,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-234973700",
    ID: 234973700,
    indexed_at: 1709673832,
    name: "Sunol canyon view",
    slug: "sunol-canyon-view-5b6ba13",
    type: "track",
    _geoloc: {
      lat: 37.51442,
      lng: -121.82989,
    },
    length: 5114.31,
    elevation_gain: 141,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10245277,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2024-01-27T18:44:50Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 80,
    distance: 5114.31,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-233592553",
    ID: 233592553,
    indexed_at: 1709673832,
    name: "Redwood Regional",
    slug: "redwood-regional-1da546f",
    type: "track",
    _geoloc: {
      lat: 37.80674,
      lng: -122.14831,
    },
    length: 9503.25,
    elevation_gain: 182,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Oakland",
    state_name: "CA",
    country_name: "United States",
    created_at: "2024-01-20T18:59:32Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 130,
    distance: 9503.25,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-232083537",
    ID: 232083537,
    indexed_at: 1709673832,
    name: "Deer Flat",
    slug: "afternoon-hike-a597e50-200",
    type: "track",
    _geoloc: {
      lat: 37.92236,
      lng: -121.94133,
    },
    length: 12940.69,
    elevation_gain: 478,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 2,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2024-01-14T18:06:31Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 203,
    distance: 12940.69,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-229549676",
    ID: 229549676,
    indexed_at: 1709673832,
    name: "Pt Reyes Bear Valley Mushroom tour",
    slug: "afternoon-hike-11f38f8-194",
    type: "track",
    _geoloc: {
      lat: 38.04006,
      lng: -122.79933,
    },
    length: 14511.31,
    elevation_gain: 195,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10234632,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 5,
    popularity: 0.603,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2024-01-01T18:31:52Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 210,
    distance: 14511.31,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-227942497",
    ID: 227942497,
    indexed_at: 1709673832,
    name: "Afternoon hike",
    slug: "afternoon-hike-f26dab9-210",
    type: "track",
    _geoloc: {
      lat: 36.51903,
      lng: -121.94962,
    },
    length: 4367.73,
    elevation_gain: 49,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10296839,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Carmel",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-12-30T22:15:30Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 65,
    distance: 4367.73,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-225776463",
    ID: 225776463,
    indexed_at: 1709673832,
    name: "Redwood Regional",
    slug: "morning-hike-61c66a2-122",
    type: "track",
    _geoloc: {
      lat: 37.807,
      lng: -122.14851,
    },
    length: 8184.78,
    elevation_gain: 105,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 3,
    popularity: 0,
    hidden: 0,
    city_name: "Oakland",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-12-23T16:54:36Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 123,
    distance: 8184.78,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-224509150",
    ID: 224509150,
    indexed_at: 1709673832,
    name: "Round Valley recovery",
    slug: "round-valley-recovery-e1021d4",
    type: "track",
    _geoloc: {
      lat: 37.86893,
      lng: -121.7501,
    },
    length: 7608.85,
    elevation_gain: 300,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027677,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Brentwood",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-12-16T17:54:39Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 131,
    distance: 7608.85,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-220670938",
    ID: 220670938,
    indexed_at: 1709673832,
    name: "Afternoon hike",
    slug: "afternoon-hike-ab8aa05-161",
    type: "track",
    _geoloc: {
      lat: 37.89556,
      lng: -121.99707,
    },
    length: 11833.83,
    elevation_gain: 356,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10684865,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-11-25T18:33:36Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 174,
    distance: 11833.83,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-217123777",
    ID: 217123777,
    indexed_at: 1709673832,
    name: "First post op recovery hike",
    slug: "first-post-op-recovery-hike-b299ad8",
    type: "track",
    _geoloc: {
      lat: 37.93921,
      lng: -121.92676,
    },
    length: 5593.27,
    elevation_gain: 162,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 2,
    rating: 2,
    photo_count: 1,
    popularity: 0.4004,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-11-11T18:01:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 76,
    distance: 5593.27,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-212365867",
    ID: 212365867,
    indexed_at: 1709673833,
    name: "Afternoon hike",
    slug: "afternoon-hike-08f90c1-136",
    type: "track",
    _geoloc: {
      lat: 37.89562,
      lng: -121.99706,
    },
    length: 7024.59,
    elevation_gain: 250,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10320917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-10-22T17:12:59Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 108,
    distance: 7024.59,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-208582092",
    ID: 208582092,
    indexed_at: 1709673833,
    name: "Afternoon hike",
    slug: "afternoon-hike-1385974-148",
    type: "track",
    _geoloc: {
      lat: 38.34113,
      lng: -119.92522,
    },
    length: 798.77,
    elevation_gain: 24,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10326699,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 4,
    popularity: 0,
    hidden: 0,
    city_name: "Strawberry",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-10-09T21:54:19Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 12,
    distance: 798.77,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-208258567",
    ID: 208258567,
    indexed_at: 1709673833,
    name: "McGee Creek Trail",
    slug: "afternoon-hike-at-parker-lake-trail-e2c4c0b",
    type: "track",
    _geoloc: {
      lat: 37.55116,
      lng: -118.80201,
    },
    length: 4416.11,
    elevation_gain: 67,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 7,
    popularity: 0,
    hidden: 0,
    city_name: "Crowley Lake",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-10-08T19:22:12Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 72,
    distance: 4416.11,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-208258566",
    ID: 208258566,
    indexed_at: 1709673833,
    name: "Morning hike at Parker Lake Trail",
    slug: "morning-hike-at-parker-lake-trail-f52854c",
    type: "track",
    _geoloc: {
      lat: 37.85287,
      lng: -119.13452,
    },
    length: 6792.14,
    elevation_gain: 200,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10035735,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 3,
    popularity: 0,
    hidden: 0,
    city_name: "June Lake",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-10-08T12:29:05Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 122,
    distance: 6792.14,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-205425803",
    ID: 205425803,
    indexed_at: 1709673833,
    name: "Horse shit trails",
    slug: "horse-shit-trails-690d839",
    type: "track",
    _geoloc: {
      lat: 37.89556,
      lng: -121.99699,
    },
    length: 10061.49,
    elevation_gain: 333,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10684865,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-09-30T16:36:40Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 150,
    distance: 10061.49,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-203564850",
    ID: 203564850,
    indexed_at: 1709673833,
    name: "God damn AllTrails",
    slug: "afternoon-hike-65cc2c8-113",
    type: "track",
    _geoloc: {
      lat: 37.92076,
      lng: -121.94141,
    },
    length: 4942.68,
    elevation_gain: 334,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-09-24T16:42:47Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 74,
    distance: 4942.68,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-201908238",
    ID: 201908238,
    indexed_at: 1709673833,
    name: "Great Basin National Park #14 - Bristlecone and Alpine Lakes Loop",
    slug: "morning-hike-at-bristlecone-and-alpine-lakes-loop-9d38e6e",
    type: "track",
    _geoloc: {
      lat: 39.01038,
      lng: -114.30716,
    },
    length: 8825.37,
    elevation_gain: 298,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10309295,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 7,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Baker",
    state_name: "NV",
    country_name: "United States",
    created_at: "2023-09-19T15:05:07Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 183,
    distance: 8825.37,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-201637651",
    ID: 201637651,
    indexed_at: 1709673833,
    name: "Great Basin National Park #14 - Baker Creek Loop",
    slug: "afternoon-hike-at-baker-creek-loop-1cb524b",
    type: "track",
    _geoloc: {
      lat: 38.97668,
      lng: -114.24564,
    },
    length: 7309.61,
    elevation_gain: 334,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10711811,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 6,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Baker",
    state_name: "NV",
    country_name: "United States",
    created_at: "2023-09-18T17:10:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 155,
    distance: 7309.61,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-199206636",
    ID: 199206636,
    indexed_at: 1709673833,
    name: "Cow hill",
    slug: "cow-hill-d856727",
    type: "track",
    _geoloc: {
      lat: 37.94129,
      lng: -121.92807,
    },
    length: 9103.45,
    elevation_gain: 301,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-09-10T14:59:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 124,
    distance: 9103.45,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-197469179",
    ID: 197469179,
    indexed_at: 1709673833,
    name: "Tomales Point",
    slug: "tomales-point-0f7b2bb",
    type: "track",
    _geoloc: {
      lat: 38.1871,
      lng: -122.9533,
    },
    length: 16047.42,
    elevation_gain: 375,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10242846,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-09-03T18:20:22Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 241,
    distance: 16047.42,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-195172808",
    ID: 195172808,
    indexed_at: 1709673833,
    name: "Fort Cronkite",
    slug: "fort-cronkite-996a7fa",
    type: "track",
    _geoloc: {
      lat: 37.83163,
      lng: -122.53401,
    },
    length: 15384.58,
    elevation_gain: 434,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10452686,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Sausalito",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-08-26T17:13:29Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 217,
    distance: 15384.58,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-193495135",
    ID: 193495135,
    indexed_at: 1709673833,
    name: "Afternoon hike at Mori Point and Sweeney Ridge Loop Trail",
    slug: "afternoon-hike-at-mori-point-and-sweeney-ridge-loop-trail-beba25d",
    type: "track",
    _geoloc: {
      lat: 37.61715,
      lng: -122.48316,
    },
    length: 11701.52,
    elevation_gain: 474,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10262633,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Pacifica",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-08-19T17:25:17Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 187,
    distance: 11701.52,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-191701044",
    ID: 191701044,
    indexed_at: 1709673833,
    name: "Morning hike at Parker Lake Trail",
    slug: "morning-hike-at-parker-lake-trail-779efbd",
    type: "track",
    _geoloc: {
      lat: 37.85293,
      lng: -119.13439,
    },
    length: 6516.82,
    elevation_gain: 203,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10035735,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 3,
    popularity: 1.001,
    hidden: 0,
    city_name: "June Lake",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-08-12T15:49:03Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 117,
    distance: 6516.82,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-191394063",
    ID: 191394063,
    indexed_at: 1709673833,
    name: "Afternoon hike at May Lake High Sierra Camp from Tioga Pass Road",
    slug: "afternoon-hike-at-may-lake-high-sierra-camp-from-tioga-pass-road-7c792a8",
    type: "track",
    _geoloc: {
      lat: 37.81159,
      lng: -119.50888,
    },
    length: 11709.45,
    elevation_gain: 333,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10028657,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 9,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Yosemite National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-08-11T20:16:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 221,
    distance: 11709.45,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-189779762",
    ID: 189779762,
    indexed_at: 1709673833,
    name: "Morning hike",
    slug: "morning-hike-586f9b4-39",
    type: "track",
    _geoloc: {
      lat: 37.80549,
      lng: -122.14649,
    },
    length: 7793.91,
    elevation_gain: 98,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Oakland",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-08-05T17:09:27Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 99,
    distance: 7793.91,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-187960471",
    ID: 187960471,
    indexed_at: 1709673833,
    name: "Afternoon hike",
    slug: "afternoon-hike-821fa74-29",
    type: "track",
    _geoloc: {
      lat: 38.04077,
      lng: -122.79998,
    },
    length: 15437.9,
    elevation_gain: 206,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10234632,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-07-29T17:17:24Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 201,
    distance: 15437.9,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-186564801",
    ID: 186564801,
    indexed_at: 1709673833,
    name: "Morning hike",
    slug: "morning-hike-e343769-26",
    type: "track",
    _geoloc: {
      lat: 37.90351,
      lng: -122.24645,
    },
    length: 6325.28,
    elevation_gain: 81,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 11025557,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Orinda",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-07-22T16:39:09Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 76,
    distance: 6325.28,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-182612816",
    ID: 182612816,
    indexed_at: 1709673833,
    name: "Indian Joe and Flag Hill",
    slug: "indian-joe-and-flag-hill-99f42c4",
    type: "track",
    _geoloc: {
      lat: 37.51594,
      lng: -121.83182,
    },
    length: 7973.05,
    elevation_gain: 333,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10306750,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-07-08T16:11:30Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 136,
    distance: 7973.05,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-181298896",
    ID: 181298896,
    indexed_at: 1709673833,
    name: "Afternoon hike at Haystack Rock",
    slug: "afternoon-hike-at-haystack-rock-fde9264",
    type: "track",
    _geoloc: {
      lat: 45.89262,
      lng: -123.96079,
    },
    length: 5368.68,
    elevation_gain: 14,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10030074,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Cannon Beach",
    state_name: "OR",
    country_name: "United States",
    created_at: "2023-07-03T17:49:21Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 77,
    distance: 5368.68,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-181132053",
    ID: 181132053,
    indexed_at: 1709673833,
    name: "Captain awesome hook spout Neptune",
    slug: "captain-awesome-hook-spout-neptune-67c6a1e",
    type: "track",
    _geoloc: {
      lat: 44.28052,
      lng: -124.10811,
    },
    length: 2013.96,
    elevation_gain: 59,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10042500,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 1,
    popularity: 1.001,
    hidden: 0,
    city_name: "Yachats",
    state_name: "OR",
    country_name: "United States",
    created_at: "2023-07-03T02:27:03Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 35,
    distance: 2013.96,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-181056669",
    ID: 181056669,
    indexed_at: 1709673833,
    name: "Afternoon hike at Face Rock Viewpoint Trail",
    slug: "afternoon-hike-at-face-rock-viewpoint-trail-a58337d",
    type: "track",
    _geoloc: {
      lat: 43.1051,
      lng: -124.4343,
    },
    length: 683.09,
    elevation_gain: 9,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10257953,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Bandon",
    state_name: "OR",
    country_name: "United States",
    created_at: "2023-07-02T22:14:06Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 14,
    distance: 683.09,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-181032317",
    ID: 181032317,
    indexed_at: 1709673833,
    name: "Afternoon hike at Sisters Rock",
    slug: "afternoon-hike-at-sisters-rock-caa2020",
    type: "track",
    _geoloc: {
      lat: 42.5924,
      lng: -124.3971,
    },
    length: 1986.56,
    elevation_gain: 61,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10255181,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 1,
    popularity: 1.001,
    hidden: 0,
    city_name: "Gold Beach",
    state_name: "OR",
    country_name: "United States",
    created_at: "2023-07-02T20:12:55Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 38,
    distance: 1986.56,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-181008561",
    ID: 181008561,
    indexed_at: 1709673833,
    name: "Afternoon hike at Nesika Beach South",
    slug: "afternoon-hike-at-nesika-beach-south-38ca895",
    type: "track",
    _geoloc: {
      lat: 42.51416,
      lng: -124.40969,
    },
    length: 3195.66,
    elevation_gain: 26,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10299340,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Gold Beach",
    state_name: "OR",
    country_name: "United States",
    created_at: "2023-07-02T18:50:11Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 49,
    distance: 3195.66,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-180969772",
    ID: 180969772,
    indexed_at: 1709673833,
    name: "Morning hike at Chetco Point Trail",
    slug: "morning-hike-at-chetco-point-trail-555d670",
    type: "track",
    _geoloc: {
      lat: 42.04736,
      lng: -124.28744,
    },
    length: 1611.78,
    elevation_gain: 29,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10041554,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Brookings",
    state_name: "OR",
    country_name: "United States",
    created_at: "2023-07-02T17:21:09Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 27,
    distance: 1611.78,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-180937589",
    ID: 180937589,
    indexed_at: 1709673834,
    name: "Morning hike at Simpson-Reed Trail",
    slug: "morning-hike-at-simpson-reed-trail-357cfba",
    type: "track",
    _geoloc: {
      lat: 41.81201,
      lng: -124.10854,
    },
    length: 2689.18,
    elevation_gain: 22,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10236108,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 2,
    popularity: 1.002,
    hidden: 0,
    city_name: "Crescent City",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-07-02T15:39:27Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 44,
    distance: 2689.18,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-180751224",
    ID: 180751224,
    indexed_at: 1709673834,
    name: "Evening hike at Lady Bird Johnson Grove Trail",
    slug: "evening-hike-at-lady-bird-johnson-grove-trail-cfa4515",
    type: "track",
    _geoloc: {
      lat: 41.30147,
      lng: -124.01591,
    },
    length: 3577.87,
    elevation_gain: 38,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10037534,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Orick",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-07-02T00:15:42Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 55,
    distance: 3577.87,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-180730466",
    ID: 180730466,
    indexed_at: 1709673834,
    name: "Afternoon hike at Trinidad Head Trail Loop",
    slug: "afternoon-hike-at-trinidad-head-trail-loop-fac0532",
    type: "track",
    _geoloc: {
      lat: 41.05714,
      lng: -124.14903,
    },
    length: 3059.71,
    elevation_gain: 103,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027194,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Trinidad",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-07-01T22:37:01Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 51,
    distance: 3059.71,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-179318988",
    ID: 179318988,
    indexed_at: 1709673834,
    name: "Twin Lakes - Horsetail Falls",
    slug: "morning-hike-a012869-4",
    type: "track",
    _geoloc: {
      lat: 38.1465,
      lng: -119.3781,
    },
    length: 4519.36,
    elevation_gain: 105,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 3,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Bridgeport",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-06-25T16:54:31Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 67,
    distance: 4519.36,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-179076484",
    ID: 179076484,
    indexed_at: 1709673834,
    name: "Afternoon hike",
    slug: "afternoon-hike-63c3ddc-9",
    type: "track",
    _geoloc: {
      lat: 38.31089,
      lng: -119.74564,
    },
    length: 6079.8,
    elevation_gain: 194,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Strawberry",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-06-24T20:15:41Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 107,
    distance: 6079.8,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-177977142",
    ID: 177977142,
    indexed_at: 1709673834,
    name: "Afternoon hike at Mount Wittenberg, Sky, Old Pine and Bear Valley Trail Loop",
    slug: "afternoon-hike-at-mount-wittenberg-sky-old-pine-and-bear-valley-trail-loop-03b92cd",
    type: "track",
    _geoloc: {
      lat: 38.04086,
      lng: -122.80012,
    },
    length: 14815.14,
    elevation_gain: 472,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10299797,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 10,
    popularity: 0.8088,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-06-19T17:33:46Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 229,
    distance: 14815.14,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-175887929",
    ID: 175887929,
    indexed_at: 1709673834,
    name: "Kings Canyon NP at General Grant Trail",
    slug: "kings-canyon-np-at-general-grant-trail-0ae0bdb",
    type: "track",
    _geoloc: {
      lat: 36.74057,
      lng: -118.9634,
    },
    length: 5367.16,
    elevation_gain: 98,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10033137,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 4,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Kings Canyon National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-06-10T22:01:07Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 89,
    distance: 5367.16,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-175864581",
    ID: 175864581,
    indexed_at: 1709673834,
    name: "Kings Canyon NP at Viola Falls",
    slug: "kings-canyon-np-at-viola-falls-c45147d",
    type: "track",
    _geoloc: {
      lat: 36.74052,
      lng: -118.96162,
    },
    length: 7525.21,
    elevation_gain: 228,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10345726,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 4,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Kings Canyon National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-06-10T19:11:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 133,
    distance: 7525.21,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-175009154",
    ID: 175009154,
    indexed_at: 1709673834,
    name: "Afternoon hike",
    slug: "afternoon-hike-3a246af--498",
    type: "track",
    _geoloc: {
      lat: 37.92084,
      lng: -121.94122,
    },
    length: 6103.01,
    elevation_gain: 325,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-06-06T17:07:22Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 109,
    distance: 6103.01,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-172722161",
    ID: 172722161,
    indexed_at: 1709673834,
    name: "Evening hike at Petroglyph Point Trail",
    slug: "evening-hike-at-petroglyph-point-trail-741a009",
    type: "track",
    _geoloc: {
      lat: 41.84503,
      lng: -121.39128,
    },
    length: 1955.06,
    elevation_gain: 80,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10008290,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Tulelake",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-05-28T00:59:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 42,
    distance: 1955.06,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-171389912",
    ID: 171389912,
    indexed_at: 1709673834,
    name: "Morning hike",
    slug: "morning-hike-d88518a--241",
    type: "track",
    _geoloc: {
      lat: 37.80547,
      lng: -122.14679,
    },
    length: 12460.12,
    elevation_gain: 311,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10758548,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Oakland",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-05-21T15:28:34Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 176,
    distance: 12460.12,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-168785301",
    ID: 168785301,
    indexed_at: 1709673834,
    name: "Afternoon hike at Feather Falls Loop Trail [CLOSED]",
    slug: "afternoon-hike-at-feather-falls-loop-trail-closed-322f624",
    type: "track",
    _geoloc: {
      lat: 39.61407,
      lng: -121.26653,
    },
    length: 13787.63,
    elevation_gain: 543,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027129,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 8,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Forbestown",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-05-08T16:45:28Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 229,
    distance: 13787.63,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-168543319",
    ID: 168543319,
    indexed_at: 1709673834,
    name: "Afternoon hike at Phantom Falls Loop",
    slug: "afternoon-hike-at-phantom-falls-loop-bc2ba6b",
    type: "track",
    _geoloc: {
      lat: 39.59566,
      lng: -121.54154,
    },
    length: 7047.61,
    elevation_gain: 142,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10020802,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 4,
    popularity: 0,
    hidden: 0,
    city_name: "Oroville",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-05-06T21:26:49Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 118,
    distance: 7047.61,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-167512550",
    ID: 167512550,
    indexed_at: 1709673834,
    name: "Mission Peak from Sunol",
    slug: "afternoon-hike-0ef4126--422",
    type: "track",
    _geoloc: {
      lat: 37.51439,
      lng: -121.82995,
    },
    length: 16232.37,
    elevation_gain: 674,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10262136,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 7,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-04-30T16:05:48Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 243,
    distance: 16232.37,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165756679",
    ID: 165756679,
    indexed_at: 1709673834,
    name: "Morning hike at Miwok and Hardy Canyon Loop",
    slug: "morning-hike-at-miwok-and-hardy-canyon-loop-29586cb",
    type: "track",
    _geoloc: {
      lat: 37.86882,
      lng: -121.75029,
    },
    length: 7646.37,
    elevation_gain: 297,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027677,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 5,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Brentwood",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-04-20T15:57:18Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 121,
    distance: 7646.37,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165540481",
    ID: 165540481,
    indexed_at: 1709673834,
    name: "Capitol Reef - Hickman Bridge",
    slug: "afternoon-hike-d753207--414",
    type: "track",
    _geoloc: {
      lat: 38.28894,
      lng: -111.22817,
    },
    length: 3913.49,
    elevation_gain: 146,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10004146,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Torrey",
    state_name: "UT",
    country_name: "United States",
    created_at: "2023-04-17T21:39:10Z",
    timezone: "America/Denver",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 74,
    distance: 3913.49,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165540480",
    ID: 165540480,
    indexed_at: 1709673834,
    name: "Afternoon hike at Cassidy Arch Trail",
    slug: "afternoon-hike-at-cassidy-arch-trail-8526e09",
    type: "track",
    _geoloc: {
      lat: 38.26402,
      lng: -111.21648,
    },
    length: 5785.21,
    elevation_gain: 221,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10015413,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 2,
    popularity: 1.002,
    hidden: 0,
    city_name: "Torrey",
    state_name: "UT",
    country_name: "United States",
    created_at: "2023-04-18T16:27:45Z",
    timezone: "America/Denver",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 123,
    distance: 5785.21,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165540479",
    ID: 165540479,
    indexed_at: 1709673834,
    name: "Petroglyphs",
    slug: "petroglyphs-11bcd3c",
    type: "track",
    _geoloc: {
      lat: 38.28808,
      lng: -111.24217,
    },
    length: 715.24,
    elevation_gain: 22,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10589913,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8,
    hidden: 0,
    city_name: "Torrey",
    state_name: "UT",
    country_name: "United States",
    created_at: "2023-04-17T23:00:52Z",
    timezone: "America/Denver",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 16,
    distance: 715.24,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165267884",
    ID: 165267884,
    indexed_at: 1709673834,
    name: "Afternoon hike at Horseshoe Bend Trail",
    slug: "afternoon-hike-at-horseshoe-bend-trail-71f07bf",
    type: "track",
    _geoloc: {
      lat: 36.8758,
      lng: -111.5026,
    },
    length: 3298.78,
    elevation_gain: 54,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10242526,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 2,
    popularity: 1.002,
    hidden: 0,
    city_name: "Page",
    state_name: "AZ",
    country_name: "United States",
    created_at: "2023-04-16T20:23:35Z",
    timezone: "America/Denver",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 49,
    distance: 3298.78,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165060299",
    ID: 165060299,
    indexed_at: 1709673834,
    name: "Afternoon hike at Shoshone Point",
    slug: "afternoon-hike-at-shoshone-point-e3f3064",
    type: "track",
    _geoloc: {
      lat: 36.03522,
      lng: -112.06925,
    },
    length: 3672.33,
    elevation_gain: 57,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10026816,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 2,
    popularity: 1.001,
    hidden: 0,
    city_name: "Fredonia",
    state_name: "AZ",
    country_name: "United States",
    created_at: "2023-04-15T22:36:02Z",
    timezone: "America/Phoenix",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 59,
    distance: 3672.33,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-165039431",
    ID: 165039431,
    indexed_at: 1709673834,
    name: "Afternoon hike at South Kaibab Trail to Ooh Aah Point",
    slug: "afternoon-hike-at-south-kaibab-trail-to-ooh-aah-point-8513724",
    type: "track",
    _geoloc: {
      lat: 36.04725,
      lng: -112.08981,
    },
    length: 5608.63,
    elevation_gain: 272,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10265905,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 1,
    popularity: 1.001,
    hidden: 0,
    city_name: "Grand Canyon",
    state_name: "AZ",
    country_name: "United States",
    created_at: "2023-04-15T20:09:45Z",
    timezone: "America/Phoenix",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 101,
    distance: 5608.63,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-163767136",
    ID: 163767136,
    indexed_at: 1709673834,
    name: "Cow hill",
    slug: "cow-hill-4be49c7",
    type: "track",
    _geoloc: {
      lat: 37.93934,
      lng: -121.92663,
    },
    length: 9779.88,
    elevation_gain: 319,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 11,
    popularity: 0.8088,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-04-08T17:23:22Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 134,
    distance: 9779.88,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-162441236",
    ID: 162441236,
    indexed_at: 1709673834,
    name: "Red Road",
    slug: "afternoon-hike-1bd36c9--400",
    type: "track",
    _geoloc: {
      lat: 37.92096,
      lng: -121.94141,
    },
    length: 7539.78,
    elevation_gain: 205,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 4,
    popularity: 0.6024,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-31T19:52:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 113,
    distance: 7539.78,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-161635019",
    ID: 161635019,
    indexed_at: 1709673835,
    name: "James’ crazy Diablo",
    slug: "afternoon-hike-f8bf09f--411",
    type: "track",
    _geoloc: {
      lat: 37.82397,
      lng: -121.92586,
    },
    length: 16737.39,
    elevation_gain: 702,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10310126,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Danville",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-25T15:56:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 291,
    distance: 16737.39,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-160904913",
    ID: 160904913,
    indexed_at: 1709673835,
    name: "Diablo Foothills Mud",
    slug: "afternoon-hike-2e0aca8--384",
    type: "track",
    _geoloc: {
      lat: 37.89701,
      lng: -121.9975,
    },
    length: 4439.47,
    elevation_gain: 178,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10403194,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-19T17:54:50Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 74,
    distance: 4439.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-160561174",
    ID: 160561174,
    indexed_at: 1709673835,
    name: "Diablo-Olympia-Poison Oak-palooza",
    slug: "evening-hike-85203ae--120",
    type: "track",
    _geoloc: {
      lat: 37.91232,
      lng: -121.88535,
    },
    length: 9912.92,
    elevation_gain: 624,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10432826,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 10,
    popularity: 0.808,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-17T22:06:06Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 204,
    distance: 9912.92,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-159653768",
    ID: 159653768,
    indexed_at: 1709673835,
    name: "Redwoods National Park - Grove of Titans",
    slug: "morning-hike-at-grove-of-titans-1948522",
    type: "track",
    _geoloc: {
      lat: 41.77255,
      lng: -124.0991,
    },
    length: 4676.1,
    elevation_gain: 63,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10453960,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 7,
    popularity: 1.007,
    hidden: 0,
    city_name: "Crescent City",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-10T18:28:27Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 86,
    distance: 4676.1,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-159637603",
    ID: 159637603,
    indexed_at: 1709673835,
    name: "Redwoods National Park - Clintonia Trail Loop",
    slug: "morning-hike-at-clintonia-trail-loop-e366d10",
    type: "track",
    _geoloc: {
      lat: 41.36373,
      lng: -124.0227,
    },
    length: 16323.02,
    elevation_gain: 397,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10267752,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 6,
    popularity: 1.006,
    hidden: 0,
    city_name: "Orick",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-09T16:05:35Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 226,
    distance: 16323.02,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-159637602",
    ID: 159637602,
    indexed_at: 1709673835,
    name: "National Park #8 - Redwoods - Trillium Falls Trail",
    slug: "afternoon-hike-at-trillium-falls-trail-6950aa0",
    type: "track",
    _geoloc: {
      lat: 41.32309,
      lng: -124.04561,
    },
    length: 5358.35,
    elevation_gain: 141,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027199,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 6,
    popularity: 1.006,
    hidden: 0,
    city_name: "Orick",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-03-08T23:43:54Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 88,
    distance: 5358.35,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-158027895",
    ID: 158027895,
    indexed_at: 1709673835,
    name: "Diablo Snow - Eagle Peak Loop",
    slug: "evening-hike-90f1f49--122",
    type: "track",
    _geoloc: {
      lat: 37.92137,
      lng: -121.94113,
    },
    length: 13606.83,
    elevation_gain: 684,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10031563,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 10,
    popularity: 0.808,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-24T22:21:54Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 246,
    distance: 13606.83,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-157725428",
    ID: 157725428,
    indexed_at: 1709673835,
    name: "Pinnacles - Chalone Peak Trail",
    slug: "afternoon-hike-at-chalone-peak-trail-5dedb42",
    type: "track",
    _geoloc: {
      lat: 36.48044,
      lng: -121.18175,
    },
    length: 14253.39,
    elevation_gain: 678,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10016346,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 4,
    popularity: 0,
    hidden: 0,
    city_name: "Paicines",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-21T17:33:13Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 240,
    distance: 14253.39,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-157633659",
    ID: 157633659,
    indexed_at: 1709673835,
    name: "National Park #7 - Pinnacles  - Balconies Cave Trail",
    slug: "afternoon-hike-at-balconies-cave-trail-4559912",
    type: "track",
    _geoloc: {
      lat: 36.49172,
      lng: -121.20959,
    },
    length: 4717.69,
    elevation_gain: 150,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10305598,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 8,
    popularity: 1.008,
    hidden: 0,
    city_name: "Paicines",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-20T22:26:43Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 74,
    distance: 4717.69,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-157574722",
    ID: 157574722,
    indexed_at: 1709673835,
    name: "Cambria - Forest Loop Trail, Ridge Trail, Bluff Trail, and Arch Trail Loop",
    slug: "morning-hike-at-forest-loop-trail-ridge-trail-bluff-trail-and-arch-trail-loop-fe70c36",
    type: "track",
    _geoloc: {
      lat: 35.54895,
      lng: -121.0937,
    },
    length: 7370.51,
    elevation_gain: 167,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10551123,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 8,
    popularity: 1.008,
    hidden: 0,
    city_name: "Cambria",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-20T16:59:18Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 114,
    distance: 7370.51,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-157461358",
    ID: 157461358,
    indexed_at: 1709673835,
    name: "Elephant Seal Vista Point",
    slug: "elephant-seal-vista-point-22c432f",
    type: "track",
    _geoloc: {
      lat: 35.66335,
      lng: -121.25761,
    },
    length: 1744.26,
    elevation_gain: 9,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10998148,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 5,
    popularity: 1.001,
    hidden: 0,
    city_name: "Cambria",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-20T00:50:02Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 32,
    distance: 1744.26,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-156452081",
    ID: 156452081,
    indexed_at: 1709673835,
    name: "Fort Bragg - Gravel Pit Road",
    slug: "afternoon-hike-at-gravel-pit-road-ce052ea",
    type: "track",
    _geoloc: {
      lat: 39.41351,
      lng: -123.76816,
    },
    length: 13628.3,
    elevation_gain: 376,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10568346,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Fort Bragg",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-13T00:30:12Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 159,
    distance: 13628.3,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-156394388",
    ID: 156394388,
    indexed_at: 1709673835,
    name: "Morning hike at Blues Beach",
    slug: "morning-hike-at-blues-beach-e1ab840",
    type: "track",
    _geoloc: {
      lat: 39.61359,
      lng: -123.78151,
    },
    length: 2235.96,
    elevation_gain: 5,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10038573,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 5,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Westport",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-12T17:09:53Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 42,
    distance: 2235.96,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-156276995",
    ID: 156276995,
    indexed_at: 1709673835,
    name: "Afternoon hike at The Ecological Staircase",
    slug: "afternoon-hike-at-the-ecological-staircase-0ff8033",
    type: "track",
    _geoloc: {
      lat: 39.37533,
      lng: -123.81652,
    },
    length: 9492.59,
    elevation_gain: 106,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10240665,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 8,
    popularity: 0.8064,
    hidden: 0,
    city_name: "Caspar",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-02-11T23:47:13Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 120,
    distance: 9492.59,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-154569471",
    ID: 154569471,
    indexed_at: 1709673835,
    name: "Fremont - Coyote Hills",
    slug: "afternoon-hike-708f3cf--386",
    type: "track",
    _geoloc: {
      lat: 37.5551,
      lng: -122.06456,
    },
    length: 10453.96,
    elevation_gain: 189,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10289803,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Fremont",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-28T19:22:17Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 138,
    distance: 10453.96,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-153753844",
    ID: 153753844,
    indexed_at: 1709673835,
    name: "Dipsea and Matt Damon",
    slug: "dipsea-and-matt-damon-b837305",
    type: "track",
    _geoloc: {
      lat: 37.89816,
      lng: -122.63436,
    },
    length: 13797.47,
    elevation_gain: 594,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Stinson Beach",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-21T19:12:37Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 223,
    distance: 13797.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-153209338",
    ID: 153209338,
    indexed_at: 1709673835,
    name: "Afternoon hike at Bill's Trail to Mount Barnabe Peak Loop",
    slug: "afternoon-hike-at-bill-s-trail-to-mount-barnabe-peak-loop-04f1911",
    type: "track",
    _geoloc: {
      lat: 38.02975,
      lng: -122.73681,
    },
    length: 12452.85,
    elevation_gain: 438,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10259527,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 5,
    popularity: 0.804,
    hidden: 0,
    city_name: "Nicasio",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-16T18:59:10Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 186,
    distance: 12452.85,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-153051237",
    ID: 153051237,
    indexed_at: 1709673835,
    name: "Morning hike",
    slug: "morning-hike-4a11654--203",
    type: "track",
    _geoloc: {
      lat: 38.00007,
      lng: -122.35584,
    },
    length: 7207.1,
    elevation_gain: 57,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10040577,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Richmond",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-15T17:35:21Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 87,
    distance: 7207.1,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-152150705",
    ID: 152150705,
    indexed_at: 1709673835,
    name: "Afternoon hike",
    slug: "afternoon-hike-c89ca36--373",
    type: "track",
    _geoloc: {
      lat: 37.92069,
      lng: -121.94132,
    },
    length: 12578.21,
    elevation_gain: 472,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-07T17:25:29Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 178,
    distance: 12578.21,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-151618612",
    ID: 151618612,
    indexed_at: 1709673835,
    name: "Afternoon hike",
    slug: "afternoon-hike-a613863--388",
    type: "track",
    _geoloc: {
      lat: 38.04084,
      lng: -122.79999,
    },
    length: 11019.68,
    elevation_gain: 148,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10234632,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 12,
    popularity: 0.8096,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-02T18:28:37Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 149,
    distance: 11019.68,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-151443389",
    ID: 151443389,
    indexed_at: 1709673835,
    name: "Afternoon hike at Falls Trail and Middle Trail",
    slug: "afternoon-hike-at-falls-trail-and-middle-trail-1e8eec0",
    type: "track",
    _geoloc: {
      lat: 37.92236,
      lng: -121.92874,
    },
    length: 9222.26,
    elevation_gain: 374,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027057,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 8,
    popularity: 0.8064,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2023-01-01T18:12:21Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 154,
    distance: 9222.26,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-150645268",
    ID: 150645268,
    indexed_at: 1709673835,
    name: "Deer flat",
    slug: "deer-flat-aa108f5",
    type: "track",
    _geoloc: {
      lat: 37.92082,
      lng: -121.94126,
    },
    length: 12348.45,
    elevation_gain: 472,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 2,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-26T18:25:48Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 152,
    distance: 12348.45,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-150377378",
    ID: 150377378,
    indexed_at: 1709673835,
    name: "Afternoon hike at Mount Olympia Loop",
    slug: "afternoon-hike-at-mount-olympia-loop-2bf283c",
    type: "track",
    _geoloc: {
      lat: 37.91231,
      lng: -121.88537,
    },
    length: 9980.11,
    elevation_gain: 625,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10432826,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8032,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-23T18:50:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 193,
    distance: 9980.11,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-150033156",
    ID: 150033156,
    indexed_at: 1709673835,
    name: "Afternoon hike at Stage, Briones to Mt Diablo, and Fairy Lantern Loop",
    slug: "afternoon-hike-at-stage-briones-to-mt-diablo-and-fairy-lantern-loop-d3696cf",
    type: "track",
    _geoloc: {
      lat: 37.89569,
      lng: -121.99674,
    },
    length: 12076.07,
    elevation_gain: 340,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10624061,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-18T17:03:25Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 151,
    distance: 12076.07,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-149627811",
    ID: 149627811,
    indexed_at: 1709673835,
    name: "Afternoon hike at Badwater Basin Salt Flats Trail",
    slug: "afternoon-hike-at-badwater-basin-salt-flats-trail-682e0e7",
    type: "track",
    _geoloc: {
      lat: 36.22986,
      lng: -116.76739,
    },
    length: 3245.31,
    elevation_gain: 2,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10006688,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 7,
    popularity: 0.8056,
    hidden: 0,
    city_name: "Death Valley",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-12T23:05:55Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 40,
    distance: 3245.31,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-149612254",
    ID: 149612254,
    indexed_at: 1709673836,
    name: "National Park #6 - Death Valley - Mosaic Canyon Trail",
    slug: "afternoon-hike-at-mosaic-canyon-trail-e449b93",
    type: "track",
    _geoloc: {
      lat: 36.57209,
      lng: -117.1446,
    },
    length: 6854.68,
    elevation_gain: 318,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10034281,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 7,
    popularity: 1.007,
    hidden: 0,
    city_name: "Stovepipe Wells",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-12T17:58:30Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 131,
    distance: 6854.68,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-149595872",
    ID: 149595872,
    indexed_at: 1709673836,
    name: "National Park #5 - Joshua Tree - Afternoon hike at Hidden Valley",
    slug: "afternoon-hike-at-hidden-valley-26751be",
    type: "track",
    _geoloc: {
      lat: 34.01265,
      lng: -116.16747,
    },
    length: 2405.81,
    elevation_gain: 43,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10035554,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 6,
    popularity: 0.8048,
    hidden: 0,
    city_name: "Desert Hot Springs",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-11T20:59:21Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 48,
    distance: 2405.81,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-148909100",
    ID: 148909100,
    indexed_at: 1709673836,
    name: "Sunol Peak Plucked in persistent precipitation",
    slug: "sunol-peak-plucked-in-persistent-precipitation-c3e878e",
    type: "track",
    _geoloc: {
      lat: 37.59492,
      lng: -121.89559,
    },
    length: 10723.96,
    elevation_gain: 591,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 11166813,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 2,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-12-03T18:16:08Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 180,
    distance: 10723.96,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-148466687",
    ID: 148466687,
    indexed_at: 1709673836,
    name: "Peregrine Peril",
    slug: "afternoon-hike-f69e505--360",
    type: "track",
    _geoloc: {
      lat: 37.89388,
      lng: -121.99436,
    },
    length: 13141.27,
    elevation_gain: 393,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10363240,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-27T17:14:30Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 183,
    distance: 13141.27,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-148311915",
    ID: 148311915,
    indexed_at: 1709673836,
    name: "Red Road Redrum",
    slug: "morning-hike-ba038e2--234",
    type: "track",
    _geoloc: {
      lat: 37.9223,
      lng: -121.94168,
    },
    length: 7563.31,
    elevation_gain: 196,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-26T17:54:54Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 91,
    distance: 7563.31,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-147536770",
    ID: 147536770,
    indexed_at: 1709673836,
    name: "Round Valley",
    slug: "round-valley-4ca82b2",
    type: "track",
    _geoloc: {
      lat: 37.86879,
      lng: -121.7501,
    },
    length: 12457.89,
    elevation_gain: 347,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10288439,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Brentwood",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-19T18:03:53Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 169,
    distance: 12457.89,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-147112826",
    ID: 147112826,
    indexed_at: 1709673836,
    name: "National Park #4 - Yosemite  - Holy snow - Stanford Point Trail",
    slug: "afternoon-hike-at-stanford-point-trail-0e9fa1f",
    type: "track",
    _geoloc: {
      lat: 37.7152,
      lng: -119.6769,
    },
    length: 9091.7,
    elevation_gain: 648,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10235912,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 11,
    popularity: 0.8159,
    hidden: 0,
    city_name: "Yosemite National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-14T17:44:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 282,
    distance: 9091.7,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-147037232",
    ID: 147037232,
    indexed_at: 1709673836,
    name: "Inspiration Point Trail",
    slug: "inspiration-point-trail-f7e4dd6--3",
    type: "track",
    _geoloc: {
      lat: 37.71532,
      lng: -119.67691,
    },
    length: 4211.15,
    elevation_gain: 329,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10235911,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Yosemite National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-13T22:57:43Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 121,
    distance: 4211.15,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-146864245",
    ID: 146864245,
    indexed_at: 1709673836,
    name: "Cow hill",
    slug: "cow-hill-afab3e9",
    type: "track",
    _geoloc: {
      lat: 37.94121,
      lng: -121.92834,
    },
    length: 8964.19,
    elevation_gain: 299,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-12T20:38:48Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 120,
    distance: 8964.19,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-146027853",
    ID: 146027853,
    indexed_at: 1709673836,
    name: "Afternoon hike",
    slug: "afternoon-hike-8b10a92--332",
    type: "track",
    _geoloc: {
      lat: 36.51255,
      lng: -121.94276,
    },
    length: 1961.14,
    elevation_gain: 14,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 0,
    popularity: 0.8,
    hidden: 0,
    city_name: "Carmel",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-11-05T21:34:15Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 50,
    distance: 1961.14,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-145267689",
    ID: 145267689,
    indexed_at: 1709673836,
    name: "Kayak hill",
    slug: "kayak-hill-0f7b2bb",
    type: "track",
    _geoloc: {
      lat: 37.94108,
      lng: -121.92816,
    },
    length: 5552.99,
    elevation_gain: 161,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 2,
    rating: 2,
    photo_count: 1,
    popularity: 0.4004,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-30T17:03:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 76,
    distance: 5552.99,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-144099163",
    ID: 144099163,
    indexed_at: 1709673836,
    name: "Deer flat to eagle peak Diablo",
    slug: "deer-flat-to-eagle-peak-diablo-99b410a",
    type: "track",
    _geoloc: {
      lat: 37.9213,
      lng: -121.94139,
    },
    length: 13513.72,
    elevation_gain: 681,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10031563,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-22T16:15:50Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 221,
    distance: 13513.72,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-142197062",
    ID: 142197062,
    indexed_at: 1709673836,
    name: "Afternoon hike at Cluster Lakes Loop Trail",
    slug: "afternoon-hike-at-cluster-lakes-loop-trail-210f760",
    type: "track",
    _geoloc: {
      lat: 40.49474,
      lng: -121.42566,
    },
    length: 19153.56,
    elevation_gain: 455,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10038502,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 11,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Old Station",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-09T17:26:44Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 331,
    distance: 19153.56,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-142197059",
    ID: 142197059,
    indexed_at: 1709673836,
    name: "National Park #3 - Lassen - hike at Chaos Crags Trail",
    slug: "afternoon-hike-at-chaos-crags-trail-e250c59",
    type: "track",
    _geoloc: {
      lat: 40.53594,
      lng: -121.55922,
    },
    length: 6931.46,
    elevation_gain: 312,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10007625,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Shingletown",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-08T22:07:44Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 117,
    distance: 6931.46,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-142197058",
    ID: 142197058,
    indexed_at: 1709673836,
    name: "Morning hike at Subway Cave Trail",
    slug: "morning-hike-at-subway-cave-trail-89fcd07",
    type: "track",
    _geoloc: {
      lat: 40.68532,
      lng: -121.41904,
    },
    length: 1128.94,
    elevation_gain: 5,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10033283,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 4,
    popularity: 0.8032,
    hidden: 1,
    city_name: "Old Station",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-09T15:39:52Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 25,
    distance: 1128.94,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-142197057",
    ID: 142197057,
    indexed_at: 1709673836,
    name: "Burney Falls",
    slug: "burney-falls-89f03f7",
    type: "track",
    _geoloc: {
      lat: 41.01301,
      lng: -121.65051,
    },
    length: 2267.36,
    elevation_gain: 55,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10012465,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 7,
    popularity: 0.8056,
    hidden: 0,
    city_name: "Burney",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-10T17:19:35Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 43,
    distance: 2267.36,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-140621225",
    ID: 140621225,
    indexed_at: 1709673836,
    name: "Short and swe… steep",
    slug: "short-and-swe-steep-9a86d53",
    type: "track",
    _geoloc: {
      lat: 37.95938,
      lng: -121.86332,
    },
    length: 6568.75,
    elevation_gain: 280,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10314935,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Antioch",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-02T15:43:00Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 96,
    distance: 6568.75,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-140413311",
    ID: 140413311,
    indexed_at: 1709673837,
    name: "Apex - Diablo foothills",
    slug: "morning-hike-6d37876--178",
    type: "track",
    _geoloc: {
      lat: 37.89707,
      lng: -121.99771,
    },
    length: 11802.6,
    elevation_gain: 390,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10758541,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-10-01T15:43:36Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 165,
    distance: 11802.6,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-139283141",
    ID: 139283141,
    indexed_at: 1709673837,
    name: "Marin Heat Wave",
    slug: "afternoon-hike-16fa2b0--329",
    type: "track",
    _geoloc: {
      lat: 37.85307,
      lng: -122.49311,
    },
    length: 12490.02,
    elevation_gain: 464,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10030136,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Sausalito",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-09-24T16:25:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 172,
    distance: 12490.02,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-138104938",
    ID: 138104938,
    indexed_at: 1709673837,
    name: "Afternoon hike at Mt. Wanda via Main Fire, Almond, and Hal Olsen Founders Trail",
    slug: "afternoon-hike-at-mt-wanda-via-main-fire-almond-and-hal-olsen-founders-trail-7d265aa",
    type: "track",
    _geoloc: {
      lat: 37.98893,
      lng: -122.12958,
    },
    length: 8865.72,
    elevation_gain: 387,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10757412,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 2,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Martinez",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-09-17T18:09:12Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 120,
    distance: 8865.72,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-136931324",
    ID: 136931324,
    indexed_at: 1709673837,
    name: "Crater Lake National Park #2 - Crater Lake - Watch Man Tower and Rim Trail",
    slug: "morning-hike-ddac1f6--184",
    type: "track",
    _geoloc: {
      lat: 42.946,
      lng: -122.16914,
    },
    length: 5444.4,
    elevation_gain: 211,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10038157,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Chiloquin",
    state_name: "OR",
    country_name: "United States",
    created_at: "2022-09-10T16:55:01Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 79,
    distance: 5444.4,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-136804811",
    ID: 136804811,
    indexed_at: 1709673837,
    name: "Mt Rainier Afternoon hike at Mount Fremont Lookout Trail via Sourdough Ridge Trail",
    slug: "afternoon-hike-at-mount-fremont-lookout-trail-via-sourdough-ridge-trail-866c7ee",
    type: "track",
    _geoloc: {
      lat: 46.91423,
      lng: -121.64153,
    },
    length: 14222.55,
    elevation_gain: 589,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10002126,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 11,
    popularity: 0.8088,
    hidden: 0,
    city_name: "Ashford",
    state_name: "WA",
    country_name: "United States",
    created_at: "2022-09-09T15:03:13Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 245,
    distance: 14222.55,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-136634671",
    ID: 136634671,
    indexed_at: 1709673837,
    name: "National Park #1 - Mt Rainier",
    slug: "evening-hike-a8f15ed--101",
    type: "track",
    _geoloc: {
      lat: 46.91727,
      lng: -121.58829,
    },
    length: 2636.81,
    elevation_gain: 123,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10041480,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Ashford",
    state_name: "WA",
    country_name: "United States",
    created_at: "2022-09-09T00:44:11Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 42,
    distance: 2636.81,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-134842641",
    ID: 134842641,
    indexed_at: 1709673837,
    name: "Afternoon hike at Granite Lake, Dick's Lake, Velma Lake, and Eagle Lake Loop",
    slug: "afternoon-hike-at-granite-lake-dick-s-lake-velma-lake-and-eagle-lake-loop-9afbe99",
    type: "track",
    _geoloc: {
      lat: 38.95201,
      lng: -120.11213,
    },
    length: 22399.13,
    elevation_gain: 840,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10267430,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 17,
    popularity: 0.6,
    hidden: 0,
    city_name: "South Lake Tahoe",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-08-29T14:38:16Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 442,
    distance: 22399.13,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-134384356",
    ID: 134384356,
    indexed_at: 1709673837,
    name: "Diablo foothills",
    slug: "diablo-foothills-e698959",
    type: "track",
    _geoloc: {
      lat: 37.89582,
      lng: -121.99688,
    },
    length: 11148.71,
    elevation_gain: 374,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10758541,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 2,
    popularity: 0.6018,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-08-27T15:58:28Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 156,
    distance: 11148.71,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-133355000",
    ID: 133355000,
    indexed_at: 1709673837,
    name: "Kennedy Grove to Wildcat Peak",
    slug: "afternoon-hike-dc40b71--314",
    type: "track",
    _geoloc: {
      lat: 37.94723,
      lng: -122.26623,
    },
    length: 14458.13,
    elevation_gain: 520,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "El Sobrante",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-08-21T16:11:21Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 196,
    distance: 14458.13,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-132047425",
    ID: 132047425,
    indexed_at: 1709673837,
    name: "Deer Flat",
    slug: "morning-hike-ab2ce2a--190",
    type: "track",
    _geoloc: {
      lat: 37.92038,
      lng: -121.94203,
    },
    length: 12319.12,
    elevation_gain: 475,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-08-14T15:14:33Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 156,
    distance: 12319.12,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-131312303",
    ID: 131312303,
    indexed_at: 1709673837,
    name: "Kayak Hill",
    slug: "evening-hike-709d00f--100",
    type: "track",
    _geoloc: {
      lat: 37.93926,
      lng: -121.92673,
    },
    length: 5580.24,
    elevation_gain: 162,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 0,
    popularity: 0.6,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-08-11T02:01:05Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 68,
    distance: 5580.24,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-130561082",
    ID: 130561082,
    indexed_at: 1709673837,
    name: "Steep Ravine and Matt Davis Trails",
    slug: "afternoon-hike-65cf25e--340",
    type: "track",
    _geoloc: {
      lat: 37.89804,
      lng: -122.64187,
    },
    length: 20546.02,
    elevation_gain: 800,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10036516,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Stinson Beach",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-08-06T17:09:09Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 317,
    distance: 20546.02,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-129455284",
    ID: 129455284,
    indexed_at: 1709673837,
    name: "Alvarado windy",
    slug: "afternoon-hike-d6d2317--269",
    type: "track",
    _geoloc: {
      lat: 37.95207,
      lng: -122.31798,
    },
    length: 12637.87,
    elevation_gain: 430,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10032368,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Richmond",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-07-31T17:02:37Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 165,
    distance: 12637.87,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-127908984",
    ID: 127908984,
    indexed_at: 1709673837,
    name: "Morning hike",
    slug: "morning-hike-7eb7eab--179",
    type: "track",
    _geoloc: {
      lat: 37.92032,
      lng: -121.94195,
    },
    length: 12194.82,
    elevation_gain: 476,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-07-23T15:48:22Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 154,
    distance: 12194.82,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-126896415",
    ID: 126896415,
    indexed_at: 1709673837,
    name: "Mori Point / Sweeney Ridge",
    slug: "afternoon-hike-1fd7b87--280",
    type: "track",
    _geoloc: {
      lat: 37.6172,
      lng: -122.48314,
    },
    length: 12035.97,
    elevation_gain: 477,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10262633,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Pacifica",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-07-17T17:09:15Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 161,
    distance: 12035.97,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-124831895",
    ID: 124831895,
    indexed_at: 1709673837,
    name: "Red Road",
    slug: "afternoon-hike-7c9966a--316",
    type: "track",
    _geoloc: {
      lat: 37.92083,
      lng: -121.94138,
    },
    length: 7242.25,
    elevation_gain: 194,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-07-06T23:34:34Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 82,
    distance: 7242.25,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-123608879",
    ID: 123608879,
    indexed_at: 1709673837,
    name: "Bear Valley Trail - Pt. Reyes",
    slug: "afternoon-hike-fb8e51c--288",
    type: "track",
    _geoloc: {
      lat: 38.04055,
      lng: -122.79932,
    },
    length: 16905.47,
    elevation_gain: 385,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10234632,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 2,
    popularity: 0,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-30T19:08:07Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 208,
    distance: 16905.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-122708297",
    ID: 122708297,
    indexed_at: 1709673837,
    name: "Kayak Hill",
    slug: "morning-hike-d18f655--168",
    type: "track",
    _geoloc: {
      lat: 37.9412,
      lng: -121.92805,
    },
    length: 6776.82,
    elevation_gain: 172,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 2,
    rating: 2,
    photo_count: 1,
    popularity: 0.4004,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-25T15:42:57Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 82,
    distance: 6776.82,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-121744961",
    ID: 121744961,
    indexed_at: 1709673837,
    name: "Alvarado",
    slug: "morning-hike-0b1ec36--126",
    type: "track",
    _geoloc: {
      lat: 37.9521,
      lng: -122.31824,
    },
    length: 12700.88,
    elevation_gain: 435,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10032368,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Richmond",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-19T16:09:48Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 161,
    distance: 12700.88,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-121224767",
    ID: 121224767,
    indexed_at: 1709673837,
    name: "Windy cow hill",
    slug: "evening-hike-65fc52e--81",
    type: "track",
    _geoloc: {
      lat: 37.94127,
      lng: -121.92834,
    },
    length: 8998.27,
    elevation_gain: 299,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-17T00:07:51Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 106,
    distance: 8998.27,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-120644061",
    ID: 120644061,
    indexed_at: 1709673837,
    name: "Tennessee Valley",
    slug: "afternoon-hike-d8330f8--294",
    type: "track",
    _geoloc: {
      lat: 37.86086,
      lng: -122.53603,
    },
    length: 11989.26,
    elevation_gain: 460,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027872,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 4,
    popularity: 0.804,
    hidden: 0,
    city_name: "Mill Valley",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-12T17:01:36Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 164,
    distance: 11989.26,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-119320030",
    ID: 119320030,
    indexed_at: 1709673837,
    name: "Apex - Olympia",
    slug: "afternoon-hike-54baf7f--264",
    type: "track",
    _geoloc: {
      lat: 37.92088,
      lng: -121.94126,
    },
    length: 17779.94,
    elevation_gain: 880,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 2,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-04T15:22:15Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 243,
    distance: 17779.94,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-119001490",
    ID: 119001490,
    indexed_at: 1709673837,
    name: "Evening hike",
    slug: "evening-hike-7221e5c--76",
    type: "track",
    _geoloc: {
      lat: 37.93931,
      lng: -121.94081,
    },
    length: 5582.26,
    elevation_gain: 82,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 2,
    rating: 2,
    photo_count: 1,
    popularity: 0.4,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-06-03T00:03:24Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 65,
    distance: 5582.26,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-118153228",
    ID: 118153228,
    indexed_at: 1709673837,
    name: "Laguna, Sky, Fire Lane, and Coast Trail Loop",
    slug: "laguna-sky-fire-lane-and-coast-trail-loop-f60bb6b",
    type: "track",
    _geoloc: {
      lat: 38.0438,
      lng: -122.85957,
    },
    length: 15694.92,
    elevation_gain: 432,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10709167,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 11,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-28T17:45:23Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 220,
    distance: 15694.92,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-117520588",
    ID: 117520588,
    indexed_at: 1709673838,
    name: "Upper Yosemite Falls Trail",
    slug: "upper-yosemite-falls-trail-dc28852--3",
    type: "track",
    _geoloc: {
      lat: 37.74149,
      lng: -119.60118,
    },
    length: 13327.45,
    elevation_gain: 1025,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10005895,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8032,
    hidden: 0,
    city_name: "Yosemite National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-24T15:31:36Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 326,
    distance: 13327.45,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-117440869",
    ID: 117440869,
    indexed_at: 1709673838,
    name: "Mirror Lake via Valley Loop Trail",
    slug: "mirror-lake-via-valley-loop-trail-5e15fb5--2",
    type: "track",
    _geoloc: {
      lat: 37.7354,
      lng: -119.56236,
    },
    length: 8276.55,
    elevation_gain: 69,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10273406,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Yosemite National Park",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-23T22:05:06Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 138,
    distance: 8276.55,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-116846817",
    ID: 116846817,
    indexed_at: 1709673838,
    name: "Afternoon hike",
    slug: "afternoon-hike-d5da28d--261",
    type: "track",
    _geoloc: {
      lat: 37.93938,
      lng: -121.92665,
    },
    length: 5652.55,
    elevation_gain: 163,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 0,
    popularity: 0.6,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-20T22:28:19Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 73,
    distance: 5652.55,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-116008583",
    ID: 116008583,
    indexed_at: 1709673838,
    name: "Mori Point and Sweeney Ridge Loop Trail",
    slug: "mori-point-and-sweeney-ridge-loop-trail-cb70ab3--2",
    type: "track",
    _geoloc: {
      lat: 37.61724,
      lng: -122.4832,
    },
    length: 11918.6,
    elevation_gain: 496,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10262633,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Pacifica",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-14T17:33:49Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 165,
    distance: 11918.6,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-115944744",
    ID: 115944744,
    indexed_at: 1709673838,
    name: "Afternoon hike",
    slug: "afternoon-hike-e96b07a--270",
    type: "track",
    _geoloc: {
      lat: 37.89704,
      lng: -121.99747,
    },
    length: 5966.55,
    elevation_gain: 223,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10320917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-12T23:05:13Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 77,
    distance: 5966.55,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-115208161",
    ID: 115208161,
    indexed_at: 1709673838,
    name: "El Diablo ",
    slug: "afternoon-hike-8dd291c--248",
    type: "track",
    _geoloc: {
      lat: 37.92197,
      lng: -121.94145,
    },
    length: 22560.81,
    elevation_gain: 1142,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10027654,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-08T16:16:01Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 351,
    distance: 22560.81,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-114723408",
    ID: 114723408,
    indexed_at: 1709673838,
    name: "Evening hike",
    slug: "evening-hike-09a630e--80",
    type: "track",
    _geoloc: {
      lat: 37.93939,
      lng: -121.92666,
    },
    length: 9568.52,
    elevation_gain: 312,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-05T23:06:17Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 111,
    distance: 9568.52,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-114255036",
    ID: 114255036,
    indexed_at: 1709673838,
    name: "Black Point Fissures Trail",
    slug: "black-point-fissures-trail-900ed5e",
    type: "track",
    _geoloc: {
      lat: 38.02578,
      lng: -119.09958,
    },
    length: 3371.88,
    elevation_gain: 5,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10263192,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 4,
    popularity: 0.6024,
    hidden: 0,
    city_name: "Lee Vining",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-01T21:09:50Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 69,
    distance: 3371.88,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-114198415",
    ID: 114198415,
    indexed_at: 1709673838,
    name: "Morning hike",
    slug: "morning-hike-832688f--136",
    type: "track",
    _geoloc: {
      lat: 37.75788,
      lng: -119.02644,
    },
    length: 1945.4,
    elevation_gain: 44,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10289140,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "June Lake",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-01T17:04:18Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 43,
    distance: 1945.4,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-114180455",
    ID: 114180455,
    indexed_at: 1709673838,
    name: "Convict Lake Loop",
    slug: "convict-lake-loop-e7e2367",
    type: "track",
    _geoloc: {
      lat: 37.59519,
      lng: -118.85268,
    },
    length: 4229,
    elevation_gain: 57,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10026943,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Crowley Lake",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-05-01T14:28:24Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 63,
    distance: 4229,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-113605821",
    ID: 113605821,
    indexed_at: 1709673838,
    name: "Wednesday hike Rhylee turns 2!",
    slug: "afternoon-hike-3366297--265",
    type: "track",
    _geoloc: {
      lat: 37.89669,
      lng: -121.99741,
    },
    length: 7266.86,
    elevation_gain: 263,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10320917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 2,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-27T23:15:22Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 95,
    distance: 7266.86,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-112998820",
    ID: 112998820,
    indexed_at: 1709673838,
    name: "Saturday Mitchell to deer flat to back creek",
    slug: "afternoon-hike-dcda54e--275",
    type: "track",
    _geoloc: {
      lat: 37.92258,
      lng: -121.9415,
    },
    length: 12700.96,
    elevation_gain: 612,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10268043,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-23T16:53:53Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 177,
    distance: 12700.96,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-112584437",
    ID: 112584437,
    indexed_at: 1709673838,
    name: "Wednesday hike",
    slug: "evening-hike-f34185c--75",
    type: "track",
    _geoloc: {
      lat: 37.93933,
      lng: -121.92669,
    },
    length: 9498.57,
    elevation_gain: 314,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-20T23:23:42Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 111,
    distance: 9498.57,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-111973698",
    ID: 111973698,
    indexed_at: 1709673838,
    name: "Apex - Burma 8 Ball",
    slug: "afternoon-hike-ce46f09--272",
    type: "track",
    _geoloc: {
      lat: 37.88525,
      lng: -121.9778,
    },
    length: 18289.5,
    elevation_gain: 821,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 11211813,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-16T15:19:03Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 254,
    distance: 18289.5,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-111530034",
    ID: 111530034,
    indexed_at: 1709673838,
    name: "Afternoon hike",
    slug: "afternoon-hike-a4351b7--263",
    type: "track",
    _geoloc: {
      lat: 37.92041,
      lng: -121.94167,
    },
    length: 8773.09,
    elevation_gain: 329,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 2,
    popularity: 0.6,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-13T23:11:45Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 105,
    distance: 8773.09,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-111140364",
    ID: 111140364,
    indexed_at: 1709673838,
    name: "Stage Road, Mount Diablo, Wall Point and Borges Trail Loop",
    slug: "stage-road-mount-diablo-wall-point-and-borges-trail-loop-e046cbb",
    type: "track",
    _geoloc: {
      lat: 37.8957,
      lng: -121.99677,
    },
    length: 13335.22,
    elevation_gain: 381,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10358641,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-10T15:55:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 169,
    distance: 13335.22,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-110710651",
    ID: 110710651,
    indexed_at: 1709673838,
    name: "Oregon Day 3 - Sahalie Falls, Koosah, and Tamolich Falls via McKenzie River Trail",
    slug: "sahalie-falls-koosah-and-tamolich-falls-via-mckenzie-river-trail-4aec1b3",
    type: "track",
    _geoloc: {
      lat: 44.34825,
      lng: -121.99646,
    },
    length: 21238.47,
    elevation_gain: 305,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10325295,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 13,
    popularity: 1.002,
    hidden: 0,
    city_name: "Blue River",
    state_name: "OR",
    country_name: "United States",
    created_at: "2022-04-07T17:42:32Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 367,
    distance: 21238.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-110609734",
    ID: 110609734,
    indexed_at: 1709673838,
    name: "Oregon Day 2 - Multnomah Falls to Wahkeena Falls Loop",
    slug: "multnomah-falls-to-wahkeena-falls-loop-0d22da4",
    type: "track",
    _geoloc: {
      lat: 45.57776,
      lng: -122.1177,
    },
    length: 19666.24,
    elevation_gain: 979,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10272606,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 9,
    popularity: 0,
    hidden: 0,
    city_name: "Cascade Locks",
    state_name: "OR",
    country_name: "United States",
    created_at: "2022-04-06T17:16:49Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 355,
    distance: 19666.24,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-110509533",
    ID: 110509533,
    indexed_at: 1709673838,
    name: "Oregon Day 1 - Trail of Ten Falls",
    slug: "trail-of-ten-falls-4858434--7",
    type: "track",
    _geoloc: {
      lat: 44.87749,
      lng: -122.65533,
    },
    length: 17760.47,
    elevation_gain: 545,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10118815,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 13,
    popularity: 1.003,
    hidden: 0,
    city_name: "Silverton",
    state_name: "OR",
    country_name: "United States",
    created_at: "2022-04-05T18:17:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 261,
    distance: 17760.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-110270835",
    ID: 110270835,
    indexed_at: 1709673838,
    name: "Apex - Pleasanton Ridge",
    slug: "afternoon-hike-b9f94c7--249",
    type: "track",
    _geoloc: {
      lat: 37.6148,
      lng: -121.88204,
    },
    length: 17008.08,
    elevation_gain: 604,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10300785,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-04-03T15:33:27Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 211,
    distance: 17008.08,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-109729162",
    ID: 109729162,
    indexed_at: 1709673838,
    name: "Wed Night - Cow Hill",
    slug: "afternoon-hike-525b841--236",
    type: "track",
    _geoloc: {
      lat: 37.94101,
      lng: -121.9282,
    },
    length: 8996.13,
    elevation_gain: 292,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-30T23:06:08Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 109,
    distance: 8996.13,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-109430452",
    ID: 109430452,
    indexed_at: 1709673838,
    name: "Apex - Rose Peak",
    slug: "afternoon-hike-9e984c1--255",
    type: "track",
    _geoloc: {
      lat: 37.50992,
      lng: -121.82859,
    },
    length: 29326.22,
    elevation_gain: 1212,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10259571,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-27T15:23:07Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 385,
    distance: 29326.22,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-108826521",
    ID: 108826521,
    indexed_at: 1709673838,
    name: "Deer “not so” flat",
    slug: "evening-hike-a8ed711--68",
    type: "track",
    _geoloc: {
      lat: 37.92089,
      lng: -121.94144,
    },
    length: 12355.64,
    elevation_gain: 474,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 4,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-23T22:57:55Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 148,
    distance: 12355.64,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-108297719",
    ID: 108297719,
    indexed_at: 1709673838,
    name: "Apex - murietta falls",
    slug: "afternoon-hike-33235e3--252",
    type: "track",
    _geoloc: {
      lat: 37.57854,
      lng: -121.69745,
    },
    length: 20968.08,
    elevation_gain: 1305,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10032187,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Livermore",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-19T15:40:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 312,
    distance: 20968.08,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-107913473",
    ID: 107913473,
    indexed_at: 1709673839,
    name: "Diablo foothills",
    slug: "evening-hike-06f2e09--71",
    type: "track",
    _geoloc: {
      lat: 37.89696,
      lng: -121.99757,
    },
    length: 7275.87,
    elevation_gain: 270,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10320917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 7,
    popularity: 0.8056,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-16T23:13:29Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 102,
    distance: 7275.87,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-107561694",
    ID: 107561694,
    indexed_at: 1709673839,
    name: "Apex - Black Diamond",
    slug: "afternoon-hike-d115090--228",
    type: "track",
    _geoloc: {
      lat: 37.96552,
      lng: -121.81569,
    },
    length: 20253.07,
    elevation_gain: 724,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10704849,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 1,
    popularity: 1.004,
    hidden: 0,
    city_name: "Antioch",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-13T15:28:23Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 272,
    distance: 20253.07,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-107109524",
    ID: 107109524,
    indexed_at: 1709673839,
    name: "Castle Rock Diablo Foothills Loop",
    slug: "castle-rock-diablo-foothills-loop-b0c2b4c",
    type: "track",
    _geoloc: {
      lat: 37.89701,
      lng: -121.99748,
    },
    length: 8192.67,
    elevation_gain: 286,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10320917,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 6,
    popularity: 0.8,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-10T00:03:15Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 116,
    distance: 8192.67,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-106626343",
    ID: 106626343,
    indexed_at: 1709673839,
    name: "Apex - Las Trampas",
    slug: "afternoon-hike-8d917ee--229",
    type: "track",
    _geoloc: {
      lat: 37.81605,
      lng: -122.04986,
    },
    length: 15327.41,
    elevation_gain: 704,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10593084,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 5,
    popularity: 0.8056,
    hidden: 0,
    city_name: "San Ramon",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-05T16:39:44Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 225,
    distance: 15327.41,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-106278835",
    ID: 106278835,
    indexed_at: 1709673839,
    name: "Cow hill",
    slug: "afternoon-hike-a5e3080--235",
    type: "track",
    _geoloc: {
      lat: 37.94252,
      lng: -121.92712,
    },
    length: 8765.9,
    elevation_gain: 292,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 2,
    rating: 2,
    photo_count: 1,
    popularity: 0.4004,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-03-02T23:51:35Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 99,
    distance: 8765.9,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-105826060",
    ID: 105826060,
    indexed_at: 1709673839,
    name: "Apex Mt Diablo Summit",
    slug: "afternoon-hike-d324a0c--227",
    type: "track",
    _geoloc: {
      lat: 37.92027,
      lng: -121.94181,
    },
    length: 21413.14,
    elevation_gain: 1072,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10284501,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-02-26T16:58:28Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 318,
    distance: 21413.14,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-105515774",
    ID: 105515774,
    indexed_at: 1709673839,
    name: "Home to kayak hill loop",
    slug: "afternoon-hike-901797a--256",
    type: "track",
    _geoloc: {
      lat: 37.93921,
      lng: -121.94074,
    },
    length: 9085.11,
    elevation_gain: 211,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-02-23T23:19:54Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 115,
    distance: 9085.11,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-105175800",
    ID: 105175800,
    indexed_at: 1709673839,
    name: "Ivan Dickson Loop",
    slug: "ivan-dickson-loop-11f9e78",
    type: "track",
    _geoloc: {
      lat: 37.9274,
      lng: -122.15856,
    },
    length: 19844.88,
    elevation_gain: 756,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10587724,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6,
    hidden: 0,
    city_name: "Martinez",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-02-20T16:49:45Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 267,
    distance: 19844.88,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-104207475",
    ID: 104207475,
    indexed_at: 1709673839,
    name: "Apex -  Marin",
    slug: "afternoon-hike-178b011--211",
    type: "track",
    _geoloc: {
      lat: 37.85282,
      lng: -122.49326,
    },
    length: 12981.48,
    elevation_gain: 466,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10030136,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Sausalito",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-02-12T16:42:02Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 178,
    distance: 12981.48,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-103419949",
    ID: 103419949,
    indexed_at: 1709673839,
    name: "Apex - McGuire peak",
    slug: "apex-mcguire-peak-ec3ba92",
    type: "track",
    _geoloc: {
      lat: 37.5153,
      lng: -121.8307,
    },
    length: 19241.25,
    elevation_gain: 829,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10365276,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-02-05T16:41:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 263,
    distance: 19241.25,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-102857688",
    ID: 102857688,
    indexed_at: 1709673839,
    name: "My hiking wife substitution",
    slug: "morning-hike-b0bef4c--119",
    type: "track",
    _geoloc: {
      lat: 37.9394,
      lng: -121.92666,
    },
    length: 9585.45,
    elevation_gain: 317,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-30T17:48:59Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 102,
    distance: 9585.45,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-102180030",
    ID: 102180030,
    indexed_at: 1709673839,
    name: "#6 Mission Peak",
    slug: "evening-hike-87db7e7--67",
    type: "track",
    _geoloc: {
      lat: 37.52696,
      lng: -121.91817,
    },
    length: 13521.67,
    elevation_gain: 687,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10261073,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Fremont",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-23T23:20:38Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 175,
    distance: 13521.67,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-101969187",
    ID: 101969187,
    indexed_at: 1709673839,
    name: "Umunhum the reliable",
    slug: "umunhum-the-reliable-9a55540",
    type: "track",
    _geoloc: {
      lat: 37.15986,
      lng: -121.90043,
    },
    length: 13048.47,
    elevation_gain: 361,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10298985,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Los Gatos",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-22T18:48:09Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 165,
    distance: 13048.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-101534614",
    ID: 101534614,
    indexed_at: 1709673839,
    name: "#4 Black Mountain",
    slug: "afternoon-hike-4bd6a60--217",
    type: "track",
    _geoloc: {
      lat: 37.33019,
      lng: -122.08576,
    },
    length: 20366.01,
    elevation_gain: 963,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10271160,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 3,
    popularity: 0.8024,
    hidden: 0,
    city_name: "Cupertino",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-17T18:15:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 286,
    distance: 20366.01,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-101265775",
    ID: 101265775,
    indexed_at: 1709673839,
    name: "#3 St Helena",
    slug: "afternoon-hike-f16ba6f--199",
    type: "track",
    _geoloc: {
      lat: 38.65271,
      lng: -122.59964,
    },
    length: 17353.16,
    elevation_gain: 666,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10018824,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 5,
    popularity: 0,
    hidden: 0,
    city_name: "Calistoga",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-15T18:53:54Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 244,
    distance: 17353.16,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-100628476",
    ID: 100628476,
    indexed_at: 1709673839,
    name: "#2 Mt Tam East Peak",
    slug: "afternoon-hike-cc06a61--214",
    type: "track",
    _geoloc: {
      lat: 37.89727,
      lng: -122.63979,
    },
    length: 26889.78,
    elevation_gain: 1028,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 5,
    popularity: 0,
    hidden: 0,
    city_name: "Stinson Beach",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-08T17:18:00Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 420,
    distance: 26889.78,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-100355193",
    ID: 100355193,
    indexed_at: 1709673839,
    name: "Mid week Cow Hill",
    slug: "afternoon-hike-91d0dbf--183",
    type: "track",
    _geoloc: {
      lat: 37.94118,
      lng: -121.92814,
    },
    length: 8889.64,
    elevation_gain: 293,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-05T23:10:20Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 107,
    distance: 8889.64,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-99985476",
    ID: 99985476,
    indexed_at: 1709673839,
    name: "#1 Diablo Summit Loop",
    slug: "mount-diablo-summit-via-mitchell-canyon-loop-d56b9fc",
    type: "track",
    _geoloc: {
      lat: 37.92239,
      lng: -121.94142,
    },
    length: 22938.19,
    elevation_gain: 1109,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10284501,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2022-01-01T17:57:17Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 358,
    distance: 22938.19,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-99732453",
    ID: 99732453,
    indexed_at: 1709673839,
    name: "Sculptured beach loop",
    slug: "afternoon-hike-3bd3185--206",
    type: "track",
    _geoloc: {
      lat: 38.04016,
      lng: -122.79954,
    },
    length: 22550.9,
    elevation_gain: 529,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10268204,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 2,
    popularity: 1.002,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-30T19:22:31Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 305,
    distance: 22550.9,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-99127795",
    ID: 99127795,
    indexed_at: 1709673839,
    name: "Fort Cronkite Loop",
    slug: "lagoon-julian-sca-and-miwok-trail-d3b1fb0",
    type: "track",
    _geoloc: {
      lat: 37.83226,
      lng: -122.53871,
    },
    length: 17628.52,
    elevation_gain: 452,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10452686,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 9,
    popularity: 0,
    hidden: 0,
    city_name: "Sausalito",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-24T19:44:26Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 236,
    distance: 17628.52,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-98924919",
    ID: 98924919,
    indexed_at: 1709673839,
    name: "Stage Road, Mount Diablo, Wall Point and Borges Trail Loop",
    slug: "stage-road-mount-diablo-wall-point-and-borges-trail-loop-7c78335",
    type: "track",
    _geoloc: {
      lat: 37.89701,
      lng: -121.99744,
    },
    length: 13633.96,
    elevation_gain: 395,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10358641,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 5,
    popularity: 0.6,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-21T18:10:14Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 205,
    distance: 13633.96,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-98792220",
    ID: 98792220,
    indexed_at: 1709673840,
    name: "Apex - Mount Olympia",
    slug: "afternoon-hike-f2501c7--183",
    type: "track",
    _geoloc: {
      lat: 37.92041,
      lng: -121.94183,
    },
    length: 19091.72,
    elevation_gain: 989,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10257880,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 9,
    popularity: 1.009,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-19T16:39:48Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 289,
    distance: 19091.72,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-98568437",
    ID: 98568437,
    indexed_at: 1709673840,
    name: "Cataract Falls",
    slug: "cataract-falls-4fe5149--3",
    type: "track",
    _geoloc: {
      lat: 37.91071,
      lng: -122.61312,
    },
    length: 13137.15,
    elevation_gain: 460,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10280821,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 13,
    popularity: 1.013,
    hidden: 0,
    city_name: "Fairfax",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-16T19:50:19Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 254,
    distance: 13137.15,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-98240826",
    ID: 98240826,
    indexed_at: 1709673840,
    name: "Apex - Burma",
    slug: "afternoon-hike-801272e--225",
    type: "track",
    _geoloc: {
      lat: 37.88516,
      lng: -121.9776,
    },
    length: 17164.98,
    elevation_gain: 861,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 11211813,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 10,
    popularity: 0,
    hidden: 0,
    city_name: "Walnut Creek",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-11T16:26:19Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 241,
    distance: 17164.98,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-98068747",
    ID: 98068747,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-6c530aa--211",
    type: "track",
    _geoloc: {
      lat: 37.94109,
      lng: -121.92813,
    },
    length: 8875,
    elevation_gain: 291,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-08T23:26:05Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 106,
    distance: 8875,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-97892824",
    ID: 97892824,
    indexed_at: 1709673840,
    name: "Apex - Eagle Peak",
    slug: "afternoon-hike-4b02507--200",
    type: "track",
    _geoloc: {
      lat: 37.92083,
      lng: -121.94125,
    },
    length: 13999.21,
    elevation_gain: 783,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10412092,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 1,
    popularity: 1.001,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-05T16:36:56Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 224,
    distance: 13999.21,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-97621788",
    ID: 97621788,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-a70145b--208",
    type: "track",
    _geoloc: {
      lat: 37.93929,
      lng: -121.92663,
    },
    length: 5712.22,
    elevation_gain: 163,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 2,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-12-02T23:38:39Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 70,
    distance: 5712.22,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-97260495",
    ID: 97260495,
    indexed_at: 1709673840,
    name: "Apex - San Francisco/ Lands End",
    slug: "afternoon-hike-5249ee8--221",
    type: "track",
    _geoloc: {
      lat: 37.79283,
      lng: -122.44622,
    },
    length: 23218.92,
    elevation_gain: 407,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10260225,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 7,
    popularity: 0.8056,
    hidden: 0,
    city_name: "San Francisco",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-27T17:41:06Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 337,
    distance: 23218.92,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-96762096",
    ID: 96762096,
    indexed_at: 1709673840,
    name: "Pinnacles - Whole Enchilada",
    slug: "afternoon-hike-92a0e7a--176",
    type: "track",
    _geoloc: {
      lat: 36.49484,
      lng: -121.17309,
    },
    length: 21732.39,
    elevation_gain: 947,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10331144,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 8,
    popularity: 1.008,
    hidden: 1,
    city_name: "Paicines",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-22T17:10:07Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 345,
    distance: 21732.39,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-96528143",
    ID: 96528143,
    indexed_at: 1709673840,
    name: "Apex - Briones Crest, Spengler and Diablo View Loop",
    slug: "orchard-toyon-canyon-lagoon-briones-crest-spengler-and-diablo-view-loop-1f8fe28",
    type: "track",
    _geoloc: {
      lat: 37.9567,
      lng: -122.1233,
    },
    length: 13559.17,
    elevation_gain: 541,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10456011,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 9,
    popularity: 0.8072,
    hidden: 0,
    city_name: "Martinez",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-20T16:41:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 200,
    distance: 13559.17,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-96035447",
    ID: 96035447,
    indexed_at: 1709673840,
    name: "Apex - Black Diamond",
    slug: "afternoon-hike-1485100--215",
    type: "track",
    _geoloc: {
      lat: 37.95886,
      lng: -121.86334,
    },
    length: 15807.41,
    elevation_gain: 690,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10655379,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 6,
    popularity: 0,
    hidden: 0,
    city_name: "Pittsburg",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-14T16:52:13Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 215,
    distance: 15807.41,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-95656702",
    ID: 95656702,
    indexed_at: 1709673840,
    name: "Le petite Conrad",
    slug: "afternoon-hike-ae614c5--189",
    type: "track",
    _geoloc: {
      lat: 37.85157,
      lng: -121.84724,
    },
    length: 8419.6,
    elevation_gain: 335,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 4,
    popularity: 0.8032,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-11T18:19:56Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 126,
    distance: 8419.6,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-95280372",
    ID: 95280372,
    indexed_at: 1709673840,
    name: "Apex - deer phat",
    slug: "afternoon-hike-48db715--195",
    type: "track",
    _geoloc: {
      lat: 37.92068,
      lng: -121.94162,
    },
    length: 12838.38,
    elevation_gain: 479,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 1,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-07T16:35:21Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 165,
    distance: 12838.38,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-94803105",
    ID: 94803105,
    indexed_at: 1709673840,
    name: "THE SUUUUUUN",
    slug: "afternoon-hike-be83ab3--206",
    type: "track",
    _geoloc: {
      lat: 37.93923,
      lng: -121.92675,
    },
    length: 5637.33,
    elevation_gain: 163,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-11-03T23:16:34Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 69,
    distance: 5637.33,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-94541497",
    ID: 94541497,
    indexed_at: 1709673840,
    name: "Apex - Sunol to Mission Peak",
    slug: "afternoon-hike-18a010d--196",
    type: "track",
    _geoloc: {
      lat: 37.51453,
      lng: -121.82999,
    },
    length: 16402.3,
    elevation_gain: 674,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10262136,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 8,
    popularity: 1.008,
    hidden: 0,
    city_name: "Sunol",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-31T15:35:55Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: true,
    duration_minutes: 218,
    distance: 16402.3,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:private",
  },
  {
    objectID: "track-94377172",
    ID: 94377172,
    indexed_at: 1709673840,
    name: "Morning hike",
    slug: "morning-hike-2c8ed85--95",
    type: "track",
    _geoloc: {
      lat: 38.00026,
      lng: -122.35585,
    },
    length: 7147.47,
    elevation_gain: 51,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 0,
    popularity: 0.6,
    hidden: 0,
    city_name: "Richmond",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-30T16:57:47Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 86,
    distance: 7147.47,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-93676840",
    ID: 93676840,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-66808e3--207",
    type: "track",
    _geoloc: {
      lat: 37.93925,
      lng: -121.94076,
    },
    length: 17101.82,
    elevation_gain: 529,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 6,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-23T17:10:16Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 238,
    distance: 17101.82,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-93304967",
    ID: 93304967,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-46f5ffb--174",
    type: "track",
    _geoloc: {
      lat: 37.93932,
      lng: -121.92669,
    },
    length: 5645.39,
    elevation_gain: 162,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 2,
    popularity: 0.6012,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-20T22:53:04Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 70,
    distance: 5645.39,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-92980048",
    ID: 92980048,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-cbf4d31--220",
    type: "track",
    _geoloc: {
      lat: 38.18905,
      lng: -122.95419,
    },
    length: 15856.45,
    elevation_gain: 375,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10242846,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Inverness",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-17T15:28:05Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 228,
    distance: 15856.45,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-92368404",
    ID: 92368404,
    indexed_at: 1709673840,
    name: "Evening hike",
    slug: "evening-hike-bf499a1--63",
    type: "track",
    _geoloc: {
      lat: 37.93929,
      lng: -121.92669,
    },
    length: 9462.63,
    elevation_gain: 314,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10307560,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: null,
    rating: null,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-13T23:09:41Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 112,
    distance: 9462.63,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-92011449",
    ID: 92011449,
    indexed_at: 1709673840,
    name: "Evening hike",
    slug: "evening-hike-1e747dd--66",
    type: "track",
    _geoloc: {
      lat: 36.88439,
      lng: -121.82662,
    },
    length: 2048.7,
    elevation_gain: 52,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 0,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8,
    hidden: 0,
    city_name: "Watsonville",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-11T00:20:18Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 33,
    distance: 2048.7,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-91996414",
    ID: 91996414,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-f14bc21--170",
    type: "track",
    _geoloc: {
      lat: 36.51815,
      lng: -121.95085,
    },
    length: 2852.95,
    elevation_gain: 27,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10296839,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Carmel-by-the-Sea",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-10T21:56:02Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 42,
    distance: 2852.95,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-91351070",
    ID: 91351070,
    indexed_at: 1709673840,
    name: "Afternoon hike",
    slug: "afternoon-hike-9fa83fe--156",
    type: "track",
    _geoloc: {
      lat: 37.93924,
      lng: -121.92677,
    },
    length: 5603.12,
    elevation_gain: 162,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10565520,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 1,
    popularity: 0.6006,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-06T23:13:59Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 70,
    distance: 5603.12,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-90984244",
    ID: 90984244,
    indexed_at: 1709673840,
    name: "Apex - Stream, East Ridge, and Canyon Trail Loop",
    slug: "stream-east-ridge-and-canyon-trail-loop-800103a",
    type: "track",
    _geoloc: {
      lat: 37.80684,
      lng: -122.14861,
    },
    length: 10870.58,
    elevation_gain: 263,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10587640,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Oakland",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-10-03T15:29:46Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 140,
    distance: 10870.58,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-88848094",
    ID: 88848094,
    indexed_at: 1709673840,
    name: "Eagle Peak to Deer Flat loop",
    slug: "afternoon-hike-c4d2ce3--193",
    type: "track",
    _geoloc: {
      lat: 37.9209,
      lng: -121.94151,
    },
    length: 13377.42,
    elevation_gain: 677,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10031563,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 2,
    popularity: 0.8016,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-09-18T15:30:55Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 216,
    distance: 13377.42,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-88086586",
    ID: 88086586,
    indexed_at: 1709673840,
    name: "Apex Redwood Regional",
    slug: "morning-hike-789ba2a--85",
    type: "track",
    _geoloc: {
      lat: 37.81675,
      lng: -122.17958,
    },
    length: 10749.8,
    elevation_gain: 365,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10272116,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 2,
    popularity: 0,
    hidden: 0,
    city_name: "Oakland",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-09-12T15:31:28Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 172,
    distance: 10749.8,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-87007870",
    ID: 87007870,
    indexed_at: 1709673840,
    name: "Apex - Pt Pinole walk",
    slug: "morning-hike-ddf9029--92",
    type: "track",
    _geoloc: {
      lat: 38.00021,
      lng: -122.35588,
    },
    length: 12735.81,
    elevation_gain: 76,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 11173853,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Richmond",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-09-05T15:36:58Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 159,
    distance: 12735.81,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-85957782",
    ID: 85957782,
    indexed_at: 1709673840,
    name: "Morning hike",
    slug: "morning-hike-84a529a--91",
    type: "track",
    _geoloc: {
      lat: 37.90543,
      lng: -122.262,
    },
    length: 8306.31,
    elevation_gain: 270,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10290982,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 4,
    rating: 4,
    photo_count: 1,
    popularity: 0.8008,
    hidden: 0,
    city_name: "Berkeley",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-08-29T15:34:26Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 122,
    distance: 8306.31,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-81954951",
    ID: 81954951,
    indexed_at: 1709673841,
    name: "Coastal Bluff Trail",
    slug: "coastal-bluff-trail-e1f979e",
    type: "track",
    _geoloc: {
      lat: 38.75825,
      lng: -123.52233,
    },
    length: 7555.89,
    elevation_gain: 67,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10019160,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 4,
    popularity: 0.6,
    hidden: 0,
    city_name: "The Sea Ranch",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-08-01T16:29:17Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 115,
    distance: 7555.89,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-81831464",
    ID: 81831464,
    indexed_at: 1709673841,
    name: "Schooner Gulch & Bowling Ball Beaches",
    slug: "schooner-gulch-bowling-ball-beaches-a7c9585",
    type: "track",
    _geoloc: {
      lat: 38.86892,
      lng: -123.65341,
    },
    length: 2940.9,
    elevation_gain: 50,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10033368,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 3,
    rating: 3,
    photo_count: 2,
    popularity: 0.6,
    hidden: 0,
    city_name: "Gualala",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-08-01T00:26:08Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 54,
    distance: 2940.9,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-81820914",
    ID: 81820914,
    indexed_at: 1709673841,
    name: "Russian Gulch Fern Canyon and Waterfall Trail Loop",
    slug: "russian-gulch-fern-canyon-and-waterfall-trail-loop-87db7e7",
    type: "track",
    _geoloc: {
      lat: 39.33066,
      lng: -123.80527,
    },
    length: 16796.38,
    elevation_gain: 467,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10439852,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 5,
    rating: 5,
    photo_count: 9,
    popularity: 1.009,
    hidden: 0,
    city_name: "Mendocino",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-07-31T19:34:53Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 215,
    distance: 16796.38,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
  {
    objectID: "track-79321821",
    ID: 79321821,
    indexed_at: 1709673841,
    name: "Eagle Peak - Slippery",
    slug: "afternoon-hike-04e299e--160",
    type: "track",
    _geoloc: {
      lat: 37.92058,
      lng: -121.94174,
    },
    length: 9974.51,
    elevation_gain: 572,
    activities: ["hiking"],
    activity_names: ["Hiking"],
    trail_id: 10274575,
    user: {
      id: 17292594,
      first_name: "Michael",
      last_name: "Coustier",
      slug: "michael-coustier",
      profile_photo_url:
        "https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvMjQ2NDI3NzEvMWJlYTNkNjFhYzgxOTcyMjkzODhmMTdjMmNjZjliNzUuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJqcGVnIiwicmVzaXplIjp7IndpZHRoIjo1MCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsLCJqcGVnIjp7InRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pc2VTY2FucyI6dHJ1ZSwicXVhbnRpc2F0aW9uVGFibGUiOjN9fX0=",
    },
    avg_rating: 0,
    rating: 0,
    photo_count: 4,
    popularity: 0,
    hidden: 0,
    city_name: "Clayton",
    state_name: "CA",
    country_name: "United States",
    created_at: "2021-07-14T16:32:25Z",
    timezone: "America/Los_Angeles",
    units: "i",
    missing_polyline: false,
    private: false,
    duration_minutes: 178,
    distance: 9974.51,
    activity: {
      uid: "hiking",
      name: "hiking",
    },
    contentPrivacy: "urn:alltrails:visibility:public",
  },
];
