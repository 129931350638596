import "./styles.css";
import { Box, Tooltip } from "@mui/joy";
import { hobbyData } from "../api/experiences";

export const PhotographyCard = () => {
  const photoData = hobbyData.photography;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        overflowY: "scroll",
        height: "100%",
      }}
    >
      {photoData.map((item, ix) => (
        <div key={ix} style={{ width: "200px", margin: "8px" }}>
          <Tooltip placement="top" title={item.description}>
            <a
              href={`images/photography/${item.img}.jpg`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`images/photography/${item.img}-small.jpg`}
                width="100%"
                alt={item.title}
              />
            </a>
          </Tooltip>
          {item.title}
        </div>
      ))}
    </div>
  );
};
