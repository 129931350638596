import "./styles.css";
import { Box, Chip, Table } from "@mui/joy";
import { hikingData } from "../api/alltrailsdata";
const metersToFeet = 3.28084;
const metersToMiles = 0.000621371;

export const HikingCard = () => {
  const totalMiles = hikingData
    .map((hd) => hd.distance)
    .reduce((sum, val) => sum + val, 0);
  const totalElevation = hikingData
    .map((hd) => hd.elevation_gain)
    .reduce((sum, val) => sum + val, 0);
  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      <Table stickyHeader>
        <thead>
          <tr>
            <th>Name</th>
            <th>Distance (mi)</th>
            <th>Elevation (ft)</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {hikingData.map((item, ix) => (
            <tr key={ix}>
              <td>
                <a
                  href={
                    "https://www.alltrails.com/explore/recording/" + item.slug
                  }
                >
                  <Chip
                    color="primary"
                    variant={ix % 2 === 0 ? "solid" : "soft"}
                  >
                    {item.name}
                  </Chip>
                </a>
              </td>
              <td>{(item.length * metersToMiles).toFixed(2)}</td>
              <td>{(item.elevation_gain * metersToFeet).toFixed(1)}</td>
              <td>{new Date(item.created_at).toLocaleDateString("en-US")}</td>
            </tr>
          ))}
          <tr>
            <td>Totals</td>
            <td>{(totalMiles * metersToMiles).toFixed(2)} miles</td>
            <td>{(totalElevation * metersToFeet).toFixed(0)} feet</td>
            <td>{hikingData.length} total hikes </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
