import "./styles.css";
import { Card } from "./Card";
import { Typography } from "@mui/joy";
import { ExperiencePage } from "./ExperiencePage";
import { ProjectPage } from "./ProjectPage";
import { HobbyPage } from "./HobbyPage";
type CareerDetailProps = {
  selectedExperience: string | null;
  selectedProject: string | null;
  selectedHobby: string | null;
};

export const CareerDetails = ({
  selectedExperience,
  selectedProject,
  selectedHobby,
}: CareerDetailProps) => {
  const showWelcomeScreen =
    !selectedExperience && !selectedProject && !selectedHobby;
  return (
    // <div style={{ flex: "2", overflowY: "scroll" }}>
    <div className="careerDetails">
      {showWelcomeScreen && (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ alignSelf: "center" }}>
            Select a work or project to view
          </Typography>
        </div>
      )}
      {selectedExperience && (
        <ExperiencePage selectedExperience={selectedExperience} />
      )}
      {selectedProject && <ProjectPage selectedProject={selectedProject} />}
      {selectedHobby && <HobbyPage selectedHobby={selectedHobby} />}
    </div>
  );
};
