import { useEffect, useState } from "react";
import { Divider } from "@mui/joy";

import { ListItem, ExperienceList } from "./ExperienceList";
import { CareerDetails } from "./CareerDetails";
import { CareerHeader } from "./CareerHeader";
import "./styles.css";
import {
  getExperiencesList,
  getProjectList,
  getHobbyList,
} from "../api/experiences.js";

export const CareerDialog = () => {
  const [experienceList, setExperienceList] = useState<ListItem[] | null>(null);
  const [projectList, setProjectList] = useState<ListItem[] | null>(null);
  const [selectedExperience, setSelectedExperience] = useState<string | null>(
    null
  );
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const [selectedHobby, setSelectedHobby] = useState<string | null>(null);
  const [hobbyList, setHobbyList] = useState<ListItem[] | null>(null);

  useEffect(() => {
    getExperiencesList().then((experienceList) => {
      setExperienceList(experienceList);
    });
    getProjectList().then((projectList) => {
      setProjectList(projectList);
    });
    getHobbyList().then((hobbyList) => {
      setHobbyList(hobbyList);
    });
    return () => console.log("if async running, stop it");
  }, []);
  return (
    <div className="careerContainer">
      <CareerHeader />
      <div className="careerInnerContainer">
        <ExperienceList
          experiences={experienceList}
          selectedExperience={selectedExperience}
          setSelectedExperience={setSelectedExperience}
          projects={projectList}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          hobbies={hobbyList}
          selectedHobby={selectedHobby}
          setSelectedHobby={setSelectedHobby}
        />
        <Divider orientation="vertical" sx={{ background: "black" }} />
        <CareerDetails
          selectedProject={selectedProject}
          selectedExperience={selectedExperience}
          selectedHobby={selectedHobby}
        />
      </div>
    </div>
  );
};
