import "./styles.css";
import { Card } from "./Card";
import { hobbyData } from "../api/experiences";
import { PhotographyCard } from "./PhotographyCard";
import { HikingCard } from "./HikingCard";

type HobbyPageProps = {
  selectedHobby: string | null;
};

export const HobbyPage = ({ selectedHobby }: HobbyPageProps) => {
  const hobby = selectedHobby
    ? hobbyData[selectedHobby as keyof typeof hobbyData]
    : null;

  //   const hobbyComponents = {
  //     photography: PhotographyCard,
  //     hiking: HikingCard,
  //   };
  //   const HobbyComponent = selectedHobby ? (
  //     hobbyComponents[selectedHobby as keyof typeof hobbyComponents]
  //   ) : (
  //     <div />
  //   );
  return (
    <>
      {hobby && (
        <>
          {selectedHobby === "photography" && <PhotographyCard />}
          {selectedHobby === "hiking" && <HikingCard />}
        </>
      )}
    </>
  );
};
