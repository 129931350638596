import React, { useState } from "react";
//, useColorScheme
import { CssVarsProvider } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
// import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { CareerDialog } from "./components/CareerDialog";

import "./App.css";

// function ModeToggle() {
//   const { mode, setMode } = useColorScheme();
//   const [mounted, setMounted] = useState(false);

//   // necessary for server-side rendering
//   // because mode is undefined on the server
//   React.useEffect(() => {
//     setMounted(true);
//   }, []);
//   if (!mounted) {
//     return null;
//   }

//   return (
//     <Sheet
//       sx={{ width: 0 }}
//       onClick={() => {
//         setMode(mode === "light" ? "dark" : "light");
//       }}
//     >
//       <LightbulbIcon sx={{ width: 16 }} />
//     </Sheet>
//   );
// }

function App() {
  const [showWelcome, setShowWelcome] = useState(false);
  return (
    <CssVarsProvider defaultMode="light">
      <Sheet
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#F2F2F2",
        }}
      >
        {/* <ModeToggle /> */}
        {showWelcome ? (
          <Sheet
            sx={{
              width: 300,
              mx: "auto", // margin left & right
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              borderRadius: "sm",
              boxShadow: "md",
              height: "20svh",
            }}
          >
            <Typography level="h3">coustier.com</Typography>
            <Button
              color="neutral"
              sx={{ mt: 1, width: 200 }}
              onClick={() => setShowWelcome(false)}
            >
              Razzle Dazzle
            </Button>
          </Sheet>
        ) : (
          <CareerDialog />
        )}
      </Sheet>
    </CssVarsProvider>
  );
}

export default App;
