import "./styles.css";
import { Card } from "./Card";
import { Chip, Input, FormControl, FormLabel } from "@mui/joy";
import { YouTube } from "@mui/icons-material";
import { experienceData } from "../api/experiences";
type ExperiencePageProps = {
  selectedExperience: string | null;
};
type ExperienceURL = {
  description: string;
  url: string;
};

export const ExperiencePage = ({ selectedExperience }: ExperiencePageProps) => {
  const expData = selectedExperience
    ? experienceData[selectedExperience as keyof typeof experienceData]
    : null;
  return (
    <Card
      sx={{
        width: "100%",
        // height: "100%",
        // backgroundColor: "#f3f3f3",
        overflowY: "auto",
        scrollbarWidth: "1px",
      }}
      data-id="experiencePage"
    >
      {expData ? (
        <div style={{ width: "100%" }}>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>Company Name</FormLabel>
            <Input sx={{ maxWidth: "256px" }} value={expData.companyName} />
          </FormControl>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>Title</FormLabel>
            <Input sx={{ maxWidth: "256px" }} value={expData.title} />
          </FormControl>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>Start Date</FormLabel>
            <Input
              sx={{ maxWidth: "256px" }}
              type="date"
              value={expData.startDate}
              slotProps={{
                input: {
                  min: "1992-03-01",
                  max: "2024-12-31",
                },
              }}
            />
          </FormControl>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>End Date</FormLabel>
            <Input
              sx={{ maxWidth: "256px" }}
              type="date"
              value={expData.endDate}
              slotProps={{
                input: {
                  min: "1992-03-01",
                  max: "2024-12-31",
                },
              }}
            />
          </FormControl>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>Overview</FormLabel>
            {/* TextArea broke due to https://github.com/mui/material-ui/issues/39105 */}
            {/* <Textarea minRows={2} maxRows={2} value={expData.overview}/> */}
            <textarea
              rows={4}
              value={expData.overview}
              style={{ resize: "none" }}
              readOnly
            />
          </FormControl>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>Tech stack</FormLabel>
            <span style={{ width: "240px" }}>
              {expData.techStack.map((tech) => (
                <Chip
                  key={tech}
                  sx={{ m: "2px" }}
                  variant="solid"
                  color="primary"
                >
                  {tech}
                </Chip>
              ))}
            </span>
          </FormControl>
          {expData.urls.length > 0 && (
            <FormControl sx={{ m: "8px" }}>
              <FormLabel>Example videos</FormLabel>
              <span>
                {expData.urls.map((url: ExperienceURL) => (
                  <Chip
                    sx={{ m: "2px" }}
                    variant="solid"
                    startDecorator={<YouTube />}
                    key={url.url}
                  >
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href={url.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url.description || expData.companyName}
                    </a>
                  </Chip>
                ))}
              </span>
            </FormControl>
          )}
        </div>
      ) : (
        <></>
      )}
    </Card>
  );
};
