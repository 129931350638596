import "./styles.css";
import Typography from "@mui/joy/Typography";
import { Avatar, AvatarGroup, Box } from "@mui/joy";

export const CareerHeader = () => {
  return (
    <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex" }}>
        <Avatar
          alt="Michael Coustier"
          src="images/me.jpg"
          sx={{ marginRight: "8px" }}
        >
          MC
        </Avatar>
        <Typography level="h3">coustier.com</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <AvatarGroup
          sx={{
            "--AvatarGroup-gap": "-12px",
          }}
        >
          <a
            href="https://www.linkedin.com/in/michael-coustier/"
            target="_blank"
            rel="noreferrer"
          >
            <Avatar
              alt="LinkedIn"
              src="images/linkedin.png"
              sx={{ marginRight: "8px" }}
              size="sm"
            >
              LI
            </Avatar>
          </a>
          <a
            href="https://github.com/curiousmike"
            target="_blank"
            rel="noreferrer"
          >
            <Avatar
              alt="github"
              src="images/github.png"
              sx={{ marginRight: "8px" }}
              size="sm"
            >
              GH
            </Avatar>
          </a>
          <a
            href="https://www.flickr.com/photos/coustier/"
            target="_blank"
            rel="noreferrer"
          >
            <Avatar
              alt="flickr"
              src="images/flickr.png"
              sx={{ marginRight: "8px" }}
              size="sm"
            >
              FK
            </Avatar>
          </a>
        </AvatarGroup>
      </Box>
    </Box>
  );
};
