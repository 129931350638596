import "./styles.css";
import { Card } from "./Card";
import { Box, Select, Option, CircularProgress, Typography } from "@mui/joy";

export type ListItem = {
  value: string;
  display: string;
};

type ExperienceListProps = {
  experiences: ListItem[] | null;
  selectedExperience: string | null;
  setSelectedExperience: any; // func
  projects: ListItem[] | null;
  selectedProject: string | null;
  setSelectedProject: any; // func
  hobbies: ListItem[] | null;
  selectedHobby: string | null;
  setSelectedHobby: any; // func
};

export const ExperienceList = ({
  experiences,
  setSelectedExperience,
  selectedExperience,
  projects,
  setSelectedProject,
  selectedProject,
  hobbies,
  selectedHobby,
  setSelectedHobby,
}: ExperienceListProps) => {
  const selectExperience = (newValue: string | null) => {
    setSelectedExperience(newValue);
    setSelectedProject(null);
    setSelectedHobby(null);
  };

  const selectProject = (newValue: string | null) => {
    setSelectedProject(newValue);
    setSelectedExperience(null);
    setSelectedHobby(null);
  };

  const selectHobby = (newValue: string | null) => {
    setSelectedHobby(newValue);
    setSelectedProject(null);
    setSelectedExperience(null);
  };

  return (
    <div style={{ flex: "1" }}>
      <Box sx={{ margin: "16px" }}>
        <Typography>Work Experience</Typography>
        <Select
          name={"workExperience"}
          value={selectedExperience}
          placeholder={"Select a work experience"}
          sx={{ width: "250px" }}
          startDecorator={
            experiences ? (
              <></>
            ) : (
              <>
                <CircularProgress size="sm" />
                Loading...
              </>
            )
          }
          disabled={!experiences}
          onChange={(e, newValue) => selectExperience(newValue)}
        >
          {experiences &&
            experiences.map((e) => (
              <Option value={e.value} key={e.value}>
                {e.display}
              </Option>
            ))}
        </Select>
      </Box>
      <Box sx={{ margin: "16px" }}>
        <Typography>Home Projects</Typography>
        <Select
          name={"homeprojects"}
          value={selectedProject}
          placeholder={"Select a project"}
          startDecorator={
            projects ? (
              <></>
            ) : (
              <>
                <CircularProgress size="sm" /> Loading...
              </>
            )
          }
          disabled={!projects}
          sx={{ width: "250px" }}
          onChange={(e, newValue) => selectProject(newValue)}
        >
          {projects &&
            projects.map((e) => (
              <Option value={e.value} key={e.value}>
                {e.display}
              </Option>
            ))}
        </Select>
      </Box>
      <Box sx={{ margin: "16px" }}>
        <Typography>Hobbies</Typography>
        <Select
          name={"hobbies"}
          value={selectedHobby}
          placeholder={"Select a hobby"}
          startDecorator={
            hobbies ? (
              <></>
            ) : (
              <>
                <CircularProgress size="sm" /> Loading...
              </>
            )
          }
          disabled={!hobbies}
          sx={{ width: "250px" }}
          onChange={(e, newValue) => selectHobby(newValue)}
        >
          {hobbies &&
            hobbies.map((e) => (
              <Option value={e.value} key={e.value}>
                {e.display}
              </Option>
            ))}
        </Select>
      </Box>
    </div>
  );
};
