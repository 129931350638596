import "./styles.css";
import { Card } from "./Card";
import { Chip, Box, Input, FormControl, FormLabel } from "@mui/joy";
import { projectData } from "../api/experiences";

type ProjectPageProps = {
  selectedProject: string | null;
};

export const ProjectPage = ({ selectedProject }: ProjectPageProps) => {
  const projData = selectedProject
    ? projectData[selectedProject as keyof typeof projectData]
    : null;
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        scrollbarWidth: "1px",
      }}
    >
      {projData && (
        <>
          <FormControl sx={{ p: "16px" }}>
            <FormLabel>Project Name</FormLabel>
            <Input size="md" value={projData.title} />
          </FormControl>
          <FormControl sx={{ p: "16px" }}>
            <FormLabel>Overview</FormLabel>
            <textarea
              rows={4}
              value={projData.description}
              style={{ resize: "none" }}
              readOnly
            />
          </FormControl>
          <Box sx={{ p: "16px" }}>
            <a href={projData.hyperlink} target="_blank" rel="noreferrer">
              <img width="200px" src={`images/${projData.image}`} alt="" />
            </a>
          </Box>
          <FormControl sx={{ m: "16px" }}>
            <FormLabel>Tech stack</FormLabel>
            <span style={{ width: "240px" }}>
              {projData.techStack.map((tech) => (
                <Chip
                  key={tech}
                  sx={{ m: "2px" }}
                  variant="solid"
                  color="primary"
                >
                  {tech}
                </Chip>
              ))}
            </span>
          </FormControl>
        </>
      )}
    </Card>
  );
};
