const experiencesList = [
  {
    value: "unqork",
    display: "Unqork",
  },
  {
    value: "pearson",
    display: "Pearson",
  },
  {
    value: "sumologic",
    display: "Sumo Logic",
  },
  {
    value: "imvu",
    display: "IMVU",
  },
  {
    value: "eamaxis",
    display: "Electronic Arts / Maxis",
  },
  {
    value: "globalvr2",
    display: "Global VR - Programmer/Project Lead",
  },
  {
    value: "ea",
    display: "Electronic Arts",
  },
  {
    value: "globalvr",
    display: "Global VR - Programmer",
  },
  {
    value: "threedeeoh",
    display: "The 3DO Company",
  },
  {
    value: "ssi",
    display: "SSI - Strategic Simulations, Inc",
  },
];
const projectList = [
  {
    value: "happytripod",
    display: "Happy Tripod",
  },
  {
    value: "wordlehelper",
    display: "Wordle Helper",
  },
  {
    value: "wordfinder",
    display: "Word Finder",
  },
];

const hobbyList = [
  {
    value: "photography",
    display: "Photography",
  },
  {
    value: "hiking",
    display: "Hiking",
  },
];

export function getExperiencesList() {
  const promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(experiencesList);
    }, 1000);
  });
  return promise;
}

export function getProjectList() {
  const promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(projectList);
    }, 500);
  });
  return promise;
}

export function getHobbyList() {
  const promise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(hobbyList);
    }, 1500);
  });
  return promise;
}

export const experienceData = {
  unqork: {
    companyName: "Unqork",
    title: "Senior UI Software Engineer",
    startDate: "2022-04-01",
    endDate: "2024-02-09",
    overview: `Frontend developer working on migration from Angular to React codebase. Refactoring
numerous functionalities using the company's updated design system. Add new
backend endpoints for a comment notification system.`,
    techStack: [
      "javascript",
      "typescript",
      "react",
      "github",
      "mongodb",
      "jest",
    ],
    urls: [],
  },
  pearson: {
    companyName: "Pearson",
    title: "Senior UI Software Engineer",
    startDate: "2020-06-01",
    endDate: "2022-03-21",
    overview: `Frontend developer on learning platform. Implemented new “code blocks” plugin,
worked with backend instituting new features for tying assets between various Pearson
platforms.`,
    techStack: [
      "javascript",
      "typescript",
      "react",
      "github",
      "styled-components",
      "jest",
    ],
    urls: [],
  },
  sumologic: {
    companyName: "Sumo Logic",
    title: "Senior UI Software Engineer",
    startDate: "2017-11-01",
    endDate: "2020-05-14",
    overview: `Core platform engineer extending companies existing platform. Including writing new
pages, updating existing features, and working on component library.`,
    techStack: [
      "javascript",
      "typescript",
      "react",
      "github",
      "storybook",
      "jest",
    ],
    urls: [
      {
        description: "Sumo Logic Overview",
        url: "https://www.youtube.com/watch?v=xAZH-T6HoGI",
      },
    ],
  },
  imvu: {
    companyName: "IMVU",
    title: "Senior Software Engineer",
    startDate: "2015-03-01",
    endDate: "2017-09-14",
    overview:
      "Tools engineer working on internal character rigging web app used to power the companies animated sticker products.",
    techStack: ["javascript", "c++", "backbone", "jquery"],
    urls: [
      {
        description: "IMVU Emoji Use",
        url: "https://www.youtube.com/watch?v=91-t_pJdqxw",
      },
    ],
  },
  eamaxis: {
    companyName: "Electronic Arts / Maxis",
    title: "Senior Gameplay Engineer",
    startDate: "2010-04-01",
    endDate: "2015-03-01",
    overview: `SimCity 2013 (PC) - User interface engineering; responsible for a multitude of UX functionality in the very UX heavy game using a custom Javascript system built in Webkit.
Darkspore (PC) -Telemetry, data visualization and other data collections written in C++, Javascript and PHP.`,
    techStack: ["javascript", "c++", "php"],
    urls: [
      {
        description: "Sim City 2013",
        url: "https://www.youtube.com/watch?v=SyIRsLoWTgA",
      },
    ],
  },
  globalvr2: {
    companyName: "GlobalVR",
    title: "Technical Producer / Lead Engineer",
    startDate: "2006-11-01",
    endDate: "2009-12-01",
    overview:
      "Helped design, task, program and schedule for : Need For Speed Carbon Arcade and Twisted Arcade",
    techStack: ["c++"],
    urls: [
      {
        description: "Need For Speed Carbon",
        url: "https://www.youtube.com/watch?v=-iOOnIHdMj8",
      },
    ],
  },
  ea: {
    companyName: "Electronic Arts",
    title: "Senior Gameplay Engineer",
    startDate: "2005-05-01",
    endDate: "2006-11-01",
    overview:
      "Gameplay engineer for Tigerwoods Golf 2006 and Tigerwoods Golf 2007",
    techStack: ["c++", "xbox360", "playstation 3"],
    urls: [
      {
        description: "Tiger Woods 2007",
        url: "https://www.youtube.com/watch?v=2NUAJmfHddU",
      },
    ],
  },
  globalvr: {
    companyName: "GlobalVR",
    title: "Software Engineer",
    startDate: "2003-05-01",
    endDate: "2005-05-01",
    overview:
      "Lead engineer on Arcade versions of Need for Speed and Need for Speed Underground",
    techStack: ["c++"],
    urls: [
      {
        description: "Need For Speed Arcade",
        url: "https://www.youtube.com/watch?v=VSyer5Razek",
      },
    ],
  },
  threedeeoh: {
    companyName: "The 3DO Company",
    title: "Software Engineer",
    startDate: "1997-07-01",
    endDate: "2003-05-01",
    overview:
      "Key team member for entire High Heat Baseball history - PC, PS1 and PS2 Gameplay, input, UI, core systems.",
    techStack: [
      "c++",
      "c",
      "Win32",
      "Windows",
      "Playstation 2",
      "Playstation 1",
    ],
    urls: [
      {
        description: "High Heat Baseball",
        url: "https://www.youtube.com/watch?v=F8eeeGrm8-0",
      },
    ],
  },
  ssi: {
    companyName: "Strategic Simulations, Inc",
    title: "Software Engineer",
    startDate: "1992-03-01",
    endDate: "1997-07-01",
    overview:
      "PC Tools on numerous games- Programmer on Dark Sun 2 - Lead Software Engineer on Pacific General",
    techStack: ["c", "pascal", "x86 assembly", "Win32", "Windows"],
    urls: [
      {
        description: "Dark Sun",
        url: "https://www.youtube.com/watch?v=JPpFGL_Eenc",
      },
      {
        description: "Pacific General",
        url: "https://www.youtube.com/watch?v=CHYQLL-B_nI",
      },
    ],
  },
};

export const projectData = {
  happytripod: {
    title: "Happy Tripod",
    description: "Find photographic opporunities near you.",
    image: "happytripod.png",
    hyperlink: "https://www.happytripod.com",
    techStack: [
      "NextJS",
      "React",
      "Prisma",
      "Postgres",
      "MapBox",
      "Clerk",
      "AWS S3",
      "MUI",
      "EXIF",
    ],
  },
  wordlehelper: {
    title: "Wordle Helper",
    description: "I wanted a tool to help me when I got stumped",
    image: "wordlehelper.jpg",
    hyperlink: "https://coustier.com/wordlehelper/",
    techStack: ["Javascript", "React", "MUI"],
  },
  wordfinder: {
    title: "Word Finder",
    description:
      "I play lots of word games where you are given letters and must make words from them.",
    image: "wordfinder.jpg",
    hyperlink: "https://coustier.com/finder/",
    techStack: ["Javascript", "React", "MUI"],
  },
};

export const hobbyData = {
  photography: [
    {
      title: "Eagle Peak",
      description: "Looking down from Eagle Peak as the storm cleared",
      img: "1",
    },
    {
      title: "Early moring",
      description: "Balloon ride in Napa Valley",
      img: "2",
    },
    {
      title: "Mt. Whitney",
      description: "Mt. Whitney looms in distance",
      img: "3",
    },
    {
      title: "Sunflower",
      description: "Davis sunflower farm as sunsets",
      img: "4",
    },
    {
      title: "Half Dome",
      description: "From the floor of Yosemite",
      img: "5",
    },
    {
      title: "Narrow",
      description: "Midway thru the narrows of Utah",
      img: "6",
    },
    {
      title: "Sheer",
      description: "Massive walls of stone surround you",
      img: "7",
    },
    {
      title: "Peek-a-boo",
      description: "Burrowing owl says hello",
      img: "8",
    },
    {
      title: "Soft touch",
      description: "Sunset on California Beach",
      img: "9",
    },
    {
      title: "Forest Hike",
      description: "Entering into a grove of mighty trees",
      img: "10",
    },
    {
      title: "Grand Sunset",
      description: "Sun sets on the Grand Canyon",
      img: "11",
    },
    {
      title: "Solo",
      description: "This hiking trail ends at a single tree",
      img: "12",
    },
    {
      title: "Glacier Point",
      description: "Majestic view of Half Dome from Glacier Point",
      img: "13",
    },
    {
      title: "Jelly",
      description: "Monterey Bay Aquarium",
      img: "14",
    },
    {
      title: "Dignified",
      description: "Bristlecone Pine from Great Basin National Park",
      img: "15",
    },
    {
      title: "Steady",
      description: "Pollinate",
      img: "16",
    },
    {
      title: "Overlook",
      description: "Flowers atop a bluff",
      img: "17",
    },
    {
      title: "Hang in there, baby",
      description: "Butterflies somewhere in eastern sierra",
      img: "18",
    },
    {
      title: "Billie",
      description: "Billie the bobcat on a hiking trail in central California",
      img: "19",
    },
    {
      title: "Rip",
      description: "Galactic center rips thru the night sky",
      img: "20",
    },
    {
      title: "Hello !",
      description: "Selfie++",
      img: "21",
    },
    {
      title: "Valley",
      description: "Somewhere in Death Valley",
      img: "22",
    },
    {
      title: "Texture",
      description: "Death Valley",
      img: "23",
    },
    {
      title: "Contrast",
      description: "This beach scene had strong contrast",
      img: "24",
    },
    {
      title: "Spire",
      description: "Intimate Bryce Canyon",
      img: "25",
    },
    {
      title: "Eastern",
      description: "Somewhere in the Easter Sierras",
      img: "26",
    },
  ],
  hiking: { title: "Hiking" },
};
